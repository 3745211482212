<template>
	<div class="question__description text">
		<div class="body__m" v-html="ans.descripcion_breve2"></div>
	</div>
	<div class="answer__description text">
		<div class="body__m answer__excerpt" v-html="ans.descripcion_breve"></div>
		<div class="read__more" @click.prevent="more($event)">{{$t('general.read')}}</div>
	</div>
	<div class="answer__header" :data-id="ans.id_content">
		<div class="answer__img">
			<img class="ofc--img tt--ab" :src="ans.imgs">
		</div>
		<div>
			<div class="answer__title">{{ans.title}}</div>
			<div class="answer__subtitle">{{ans.claim}}</div>
		</div>
	</div>

	<Sharebar :shareButtonControl="false" :txalosNumber="ans.likes" :id="ans.id_content"/>
</template>
<script>
import { defineComponent } from "vue";
import Sharebar from "@/components/Sharebar.vue";

export default defineComponent({
    name: "AnswerExcerpt",
    components: {
        Sharebar
    },
    props:{
		ans:Object,
		trim:{
			type:Boolean,
			default:true
		}

    },
    mounted() {
		this.countLines();
    },
    computed: {
        language() {
             return this.$i18n.locale || document.documentElement.lang;
        }
    },
	methods: {
		strippedContent: function(string) {
			return string.replace(/<\/?[^>]+>/ig, " ");
		},
		npalabras(str){
			let np = str.split(" ");
			return np.length;
		},
		truncateword(str, no_words) {
			if (str.split(" ").length> no_words){
				let st = str.split(" ").splice(0,no_words).join(" ");
				return st+' <span class="morepoints"> ...</span>';
			}else{
				return str;
			}
		},
		moreword(str,no_words){
			if (str.split(" ").length > no_words){
				let st = str.split(" ").splice(no_words).join(" ");
				return st;
			}else{
				return false;
			}
		},
		more($event){
			$event.target.parentNode.firstChild.classList.add("read--more");
			$event.target.style.display='none';
		},
		countLines() {
			document.querySelectorAll('.b-excerpt').forEach(function(e){
				var divHeight = e.offsetHeight
				var lineHeight = parseInt(e.style.lineHeight);
				var lines = divHeight / lineHeight;
				if (lines <=5){
					e.parentNode().querySelector('.read-more').style.display='none';
				}
			});
		}
	}
 })

</script>
