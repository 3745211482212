<template>
	<ion-page>
		<Header-Start navClose :closeMe="closeMe" :transparent="false" />
		<ion-content class="app__switch" :fullscreen="true" :forceOverscroll="false">
			<div class="form__content">
				<div class="wrp flex__wrp direction--column form--fixed">
					<div class="app__switch">
						<div class="app__img">
							<router-link v-bind:to="'/'">
								<img class="" src="../assets/icon/zukesan-app-icon.svg" />
								<div class="app__label">{{ $t("alderdikide.app") }}</div>
							</router-link>
						</div>
						<div class="section__header">
							<h1 class="section__title title__l text--center">{{ $t("alderdikide.title") }}</h1>
						</div>
						<div class="text">
							<p class="body__s text--center">{{ $t("alderdikide.text1") }}<a :href="'mailto:' + $support_email + '?subject=' + encodeURIComponent(this.$t('alderdikide.supportSubject'))">{{ $support_email }}</a>{{ $t("alderdikide.text2") }}</p>
						</div>
					</div>
					<div class="form__actions">
						<a :href="redirectToApp" target="_blank" class="btn btn--base">
                            {{ $t("alderdikide.cta") }}
                        </a>
					</div>
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>
<script>
import { defineComponent } from "vue";
import { IonContent, IonPage, isPlatform } from "@ionic/vue";
import HeaderStart from "@/components/HeaderStart.vue";

export default defineComponent({
    name: "AlderdikideItem",
    components: {
		IonContent,
		IonPage,
		HeaderStart,
    },
    props:{
		closeMe: {
			type: Function,
			default: () => {},
		},
    },
    setup() {

    },
    computed: {
        language() {
             return this.$i18n.locale || document.documentElement.lang;
        },
        redirectToApp() {
            let redirectURL = "/";

            if (isPlatform("desktop") || isPlatform("mobileweb")) {
                redirectURL = process.env.VUE_APP_WEB_REDIRECT;
            } else if (isPlatform("ios")) {
                redirectURL = process.env.VUE_APP_APP_STORE_REDIRECT;
            } else if (isPlatform("android")) {
                redirectURL = process.env.VUE_APP_GOOGLE_PLAY_REDIRECT;
            }
            console.log(redirectURL);
            return redirectURL;
        },
    },
	methods: { }
 })

</script>
