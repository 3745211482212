<template>
    <ion-header>
        <div class="page__header fixed" v-bind:class="transparent?'':'bg--header'">
            <div class="menu__btn">
                <sd-button v-if="navClose" auxClass="actionbar__front" modifiers="link" @click.prevent="closeMe">
                    <svg-icon svg="close"></svg-icon>
                </sd-button>
            </div>
            <header-logo />
            <div class="onboarding__lang" @click.prevent="this.languageSwitch()">
                <div class="lang__menu" v-if="langMenu && !navClose">
                    <div v-bind:class="this.$i18n.locale == 'eu' ? 'active' : ''" class="lang__item eu">
                        <!-- <ion-button fill="clear" class="ghost__btn menu__item__text" :router-link="'/eu/'+this.route.path.slice(4)" router-direction="replace" :router-animation="false">Eu</ion-button> -->
                        <ion-button fill="clear" class="ghost__btn menu__item__text" @click.prevent="changeLang('eu')">Eu</ion-button>
                    </div>
                    <div v-bind:class="this.$i18n.locale == 'es' ? 'active' : ''" class="lang__item es">
                        <!-- <ion-button fill="clear" class="ghost__btn menu__item__text" :key="'forceReload'" :router-link="'/es/'+this.route.path.slice(4)" router-direction="replace" :router-animation="false">Es</ion-button> -->
                        <ion-button fill="clear" class="ghost__btn menu__item__text" @click.prevent="changeLang('es')">Es</ion-button>
                    </div>
                </div>
                <div class="lang__anchor" v-if="langMenu && !navClose"></div>
            </div>
        </div>
    </ion-header>
</template>
<script>
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useIonRouter, IonHeader, IonButton,  } from "@ionic/vue";
import HeaderLogo from "@/components/HeaderLogo.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import SdButton from "@/components/Button.vue";
export default defineComponent({
    name: "HeaderMenu",
    components: {
        IonHeader,
        IonButton,
        HeaderLogo,
        SvgIcon,
        SdButton,
    },
    props: {
        MenuClose: {
            type: Boolean,
            default: false,
        },
        MenuOpen: {
            type: Boolean,
            default: false,
        },
        navClose: {
            type: Boolean,
            default: false,
        },
        langMenu: {
            type: Boolean,
            default: true,
        },
        closeMe: {
            type: Function,
            default: () => {},
        },
        transparent: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        const ionRouter = useIonRouter();
        const router = useRouter();
        const route = useRoute();

        // const animation = createAnimation();
            // .addElement(myElementRef.value)
            // .duration(1000)
            // .fromTo('opacity', '1', '0.5');

        return { ionRouter, router, route };
    },
    computed: {
        language() {
            return this.$i18n.locale || document.documentElement.lang;
        },
    },
    watch() {
        const lang = this.$i18n.locale;
        this.$el.querySelector(".lang__item." + lang)?.classList.add("active");
    },
    mounted() {
        const lang = this.$i18n.locale;
        this.$el.querySelector(".lang__item." + lang)?.classList.add("active");
    },
    methods: {
        languageSwitch() {
            if (this.$el.querySelector(".onboarding__lang")?.classList.contains("l-open")) {
                this.$el.querySelector(".onboarding__lang")?.classList.remove("l-open");
            } else {
                this.$el.querySelector(".onboarding__lang")?.classList.add("l-open");
            }
        },
        changeLang(lang) {
            // const animation = () => { return false; }
            // const animation = createAnimation()
            //   .addElement(document.body)
            //   .duration(1000)
            //   .fromTo('opacity', '1', '0.5');
            this.ionRouter.navigate(`/${lang}/${this.route.path.slice(4)}`, 'root', 'replace');
            // this.router.replace({ path: `/${lang}/${this.route.path.slice(4)}`, animation: 'none' });
            // this.router.push({ path: `/${lang}/${this.route.path.slice(4)}`, replace: true, animated: false });
        }
    },
});
</script>
