<template>
    <ion-header>
        <div class="page__header menu__header bg--header border--header principal" :class="cl">
            <div class="menu__btn" @click.prevent="openMenu">
                <div>
                    <img class="open--menu tt--ab" src='../assets/icon/menu-ham.svg'>
                    <img class="close--menu  tt--ab" src='../assets/icon/menu-close.svg'>
                </div>
            </div>
            <header-logo/>
            <router-link  class="menu__user" v-bind:to="'/'+language+'/profile'">
                <img src='../assets/icon/menu-user.svg'>
                <span class="new__messages" :style="mensajes!='0' ?'opacity:1':'opacity:0'">
                    <span class="pretitle"  v-html ="mensajes"></span>
                </span>
            </router-link>
			<div class="header__title">
				<h1>{{title}}</h1>
			</div>
        </div>
    </ion-header>
</template>

<script>
import { defineComponent } from "vue";
import { IonHeader } from "@ionic/vue";
import HeaderLogo from "@/components/HeaderLogo.vue";
export default defineComponent({
    name: "HeaderMenu",
        components: {
        IonHeader,
        HeaderLogo,
    },
    props:{
        MenuClose: {
            type: Boolean,
            default: false
        },
        MenuOpen: {
            type: Boolean,
            default: false
        },
        mensajes:{
            type: Number,
            default: 0
        },
		cl: String,
		title:String
    },
    computed: {
        language() {
           return this.$i18n.locale || document.documentElement.lang;
        },

    },
    methods:{
        openMenu(){
            document.querySelectorAll('.menu__header').forEach(function(e){
            e.classList.toggle('menu--opened');
            })
            document.querySelectorAll('.menu__container').forEach(function(e){
            e.classList.toggle('menu--opened');
            })
        }
    }
});
</script>
