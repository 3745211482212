<template>
	<footer class="page__footer">
		<div class="wrp">
			<div class="footer__social__links">
				<a href="https://www.instagram.com/eajpnv/" target="_blank" class="instagram__link social__link"><img src='../assets/icon/social/instagram.svg'></a>
				<a href="https://twitter.com/eajpnv" target="_blank" class="twitter__link social__link"><img src='../assets/icon/social/twitter.svg'></a>
				<a href="https://www.linkedin.com/company/eajpnv/" target="_blank" class="linkedin__link social__link"><img src='../assets/icon/social/linkedin.svg'></a>
				<a href="https://telegram.me/eajpnveus" target="_blank" class="telegram__link social__link"><img src='../assets/icon/social/telegram.svg'></a>
				<a href="https://www.facebook.com/eajpnv" target="_blank" class="facebook__link social__link"><img src='../assets/icon/social/facebook.svg'></a>
				<a href="https://www.youtube.com/user/Multimediaeaj" target="_blank" class="youtube__link social__link"><img src='../assets/icon/social/youtube.svg'></a>
			</div>
			<div class="footer__end text--center">
				<a :href="$t('footer.pnv.url')" target="_blank" class="footer__end__item">{{$t("footer.pnv.web")}}</a>
				<a :href="$t('forms.legal.url2')" target="_blank" class="footer__end__item">{{$t("footer.privacy")}}</a>
				<a :href="$t('forms.legal.url')" target="_blank" class="footer__end__item">{{$t("footer.legal")}}</a>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
    name: "footerItem"

    }

</script>
