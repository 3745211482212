<template>
	<div class="tablet__header">
		<div class="menu__btn"></div>
		<header-logo />
		<div class="onboarding__lang" @click.prevent="this.languageSwitch()">
			<div class="lang__menu" v-if="langMenu && !navClose">
				<div v-bind:class="this.$i18n.locale == 'eu' ? 'active' : ''" class="lang__item eu">
					<ion-button fill="clear" class="ghost__btn menu__item__text" :router-link="'/eu/'+this.route.path.slice(4)" router-direction="none" :router-animation="false">Eu</ion-button>
				</div>
				<div v-bind:class="this.$i18n.locale == 'es' ? 'active' : ''" class="lang__item es">
					<ion-button fill="clear" class="ghost__btn menu__item__text" :key="'forceReload'" :router-link="'/es/'+this.route.path.slice(4)" router-direction="none" :router-animation="false">Es</ion-button>
				</div>
			</div>
			<div class="lang__anchor" v-if="langMenu && !navClose"></div>
		</div>
	</div>
</template>
<script>
import { defineComponent } from "vue";
import { IonButton } from "@ionic/vue";
import HeaderLogo from "@/components/HeaderLogo.vue";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
	name: "HeaderMenu",
	components: {
		IonButton,
		HeaderLogo,
	},
	props: {
		MenuClose: {
			type: Boolean,
			default: false,
		},
		MenuOpen: {
			type: Boolean,
			default: false,
		},
		navClose: {
			type: Boolean,
			default: false,
		},
		langMenu: {
			type: Boolean,
			default: true,
		},
		closeMe: {
			type: Function,
			default: () => {},
		},
		transparent: {
			type: Boolean,
			default: true,
		},
	},
	setup() {
		const router = useRouter();
		const route = useRoute();

		return {
			router,
			route,
		};
	},
	computed: {
		language() {
			return this.$i18n.locale || document.documentElement.lang;
		},
	},
	watch() {
		const lang = this.$i18n.locale;
		this.$el.querySelector(".lang__item." + lang)?.classList.add("active");
	},
	mounted() {
		const lang = this.$i18n.locale;
		this.$el.querySelector(".lang__item." + lang)?.classList.add("active");
	},
	methods: {
		languageSwitch() {
			if (this.$el.querySelector(".onboarding__lang")?.classList.contains("l-open")) {
				this.$el.querySelector(".onboarding__lang")?.classList.remove("l-open");
			} else {
				this.$el.querySelector(".onboarding__lang")?.classList.add("l-open");
			}
		},
	},
});
</script>
