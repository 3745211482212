<template>
    <div class="main__menu__item" :class="tabletOnly">
		<router-link class="main__menu__link" v-bind:to="'/'+language+'/'+nested.href" >
			<span class="menu__item__icon"><Svg-Icon :svg="nested.icon"/></span>
			<span class="menu__item__text">{{$t(nested.title)}}</span>
		</router-link>
    </div>
</template>
<script>
import { defineComponent } from "vue";
import SvgIcon from "@/components/SvgIcon.vue";

export default defineComponent({
    name: "RegistrationItem",
    components: {
	SvgIcon

    },
    props:{
		nested: Object,
		tabletOnly: String
	},
    computed: {
        language() {
             return this.$i18n.locale || document.documentElement.lang;
        },
    },
    methods:{

    }
 })

</script>
