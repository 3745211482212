<template>
    <ion-page>
        <Header-Title :position="'center'" :title="$t('categories.galdetu')" :principal="true" />
        <ion-content :fullscreen="true" :forceOverscroll="false">
            <div class="page__body">
              <div class="spin"><ion-spinner></ion-spinner></div>
                <div class="wrp wrp-spin">
                    <div class="area__container">
                        <div class="featured__burukide" v-bind:key="buru" v-for="buru in this.burukideak">
                            <router-link class="burukide__link flex__wrp align--center" v-bind:to="'/' + this.language + '/chat/' + buru.id_content" replaceUrl="true">
                                <div class="flex align--center">
                                    <div class="burukide__img">
                                        <img class="ofc--img tt--ab" :src="buru.imgs" />
                                    </div>
                                    <div>
                                        <div class="burukide__title">{{ buru.title }}</div>
                                        <div class="burukide__subtitle">{{ buru.claim }}</div>
                                        <div class="burukide__tags__wrp">
                                            <div class="burukide__tag" v-bind:key="tag" v-for="tag in buru.tags">
                                                {{ tag.acr }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="burukide__next">
                                    <img src="../assets/icon/forward.svg" />
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>
<script>
import { defineComponent } from "vue";
import { IonContent, IonPage, alertController, IonSpinner } from "@ionic/vue";
import HeaderTitle from "@/components/HeaderTitle.vue";
// import "@/assets/js/ValidationRegister/init";
// import InputInfo from "@/assets/js/InputInfo/InputInfo";
// const { InputInfo } = require("@/assets/js/InputInfo/InputInfo");
// import { Preferences } from '@capacitor/preferences';

export default defineComponent({
    name: "RegistrationItem",
    components: {
        IonContent,
        IonPage,
        HeaderTitle,
        IonSpinner
    },
    props: {},
    data() {
        return {
            from: null,
            total: 0,
            burukideak: [],
        };
    },

    setup() {

    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.from = from;
        });
    },
    ionViewWillEnter() {
      this.$el.querySelector('.page__body').classList.add('opened')
    },
    ionViewDidEnter() {
      this.$el.querySelector('.page__body').classList.remove('opened');
    },
    ionViewDidLeave(){
      if(this.$el.querySelector('.wrp-spin')){
      this.$el.querySelector('.wrp-spin').classList.remove('wrp-spin');
    }
      if(this.$el.querySelector('.spin')){
        this.$el.querySelector('.spin').remove();
      }
    },
    async created() {
        this.connect();
    },
    mounted() {},
    computed: {
        language() {
            return this.$i18n.locale || document.documentElement.lang;
        },
    },
    methods: {
        async presentAlert() {
            const alert = await alertController.create({
                cssClass: "my-custom-class",
                header: "ERROR",
                subHeader: "Lo sentimos ha ocurrido un error",
                message: "Error de Red no se ha podido conectar con el servidor.",
                buttons: ["OK"],
            });
            await alert.present();
            const { role } = await alert.onDidDismiss();
            this.$router.push("/" + this.language + "/onboardinguser");
            console.log("onDidDismiss resolved with role", role);
        },
		async loadData() {
			var that = this;
			that.burukideak = await this.getStorage("burukideak"+ this.$i18n.locale, true);
		},
		async connect(arg){
			var that = this;
			if(arg){
				that.saveData('timestamp','')
			}
			//const access_token = await this.getStorage("access_token", true);
			//const user = await this.getStorage("user", true);
			//const user_id=user.user_id;
			const timestamp = await this.getStorage("timestamp", true);
		
			let endpoint = this.$api_host + "/onboard/list/?lang=" + this.$i18n.locale ;
		
			if(timestamp ==null || parseInt(timestamp)+(144000) < Date.now() || timestamp == ''){
		
				that.$axiosPrivate.get(endpoint
				).then((response) => {
					if (response.status === 200) {
		
						that.burukideak = response.data.burukideak;
		
						that.saveData('noticiasOn'+ this.$i18n.locale, response.data.noticias);
						that.saveData('burukideak'+ this.$i18n.locale, response.data.burukideak);
						that.saveData('agendaOn'+ this.$i18n.locale, response.data.agenda);
						if(response.data.encuesta.length >0){
							that.saveData('encuesta'+ this.$i18n.locale, response.data.encuesta[0].encuesta[0]);
						}else{
							that.saveData('encuesta'+ this.$i18n.locale,'');
						}
						that.saveData('destacadosOn'+ this.$i18n.locale, response.data.destacados);
		
						console.log('serverdata')
						//document.querySelector('.chat__messages__container').scrollTo(0,document.querySelector('.chat__messages__container').scrollHeight);
		
					}
				}).catch((error) => {
		
					if (error.response.status === 409) {
						this.openToastOptions(`Ha ocurrido un error`, "danger");
					} else {
						this.openToastOptions(error.response.data.error, "danger");
					}
		
				});
		
			}else{
				that.loadData();
				console.log('loaddata')
			}
		}
    }
});
</script>
