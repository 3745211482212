<template>
<!--     <ion-button v-if="submit" type="submit" :id="id" :class="localClass" :name="name" :disabled="disabled"><slot></slot></ion-button>
    <ion-button v-else-if="type === 'button'" :id="id" :class="localClass" :disabled="disabled"><slot></slot></ion-button> -->
    <button v-if="submit" type="submit" :id="id" :class="localClass" :name="name" :disabled="disabled"><slot></slot></button>
    <button v-else-if="type === 'button'" :id="id" :class="localClass" :disabled="disabled"><slot></slot></button>
    <a v-else :id="id" :class="[localClass, {[compClass + '--disabled']: disabled}]" :href="href" :target="target" :rel="localRel"><slot></slot></a>
</template>

<script>
//import { IonButton } from "@ionic/vue";
import { defineComponent } from "vue";
export default defineComponent({
    name: "ButtonItem",
    components: {
        //IonButton,
    },
    props: {
        auxClass: String,
        jsClass: String,
        modifiers: String,
        id: String,
        submit: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "a",
        },
        text: String,
        href: {
            type: String,
            default: "#",
        },
        target: String,
        name: {
            type: String,
            default: "formSubmit",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        data: Array,
    },
    data() {
        return {
            compClass: "btn",
            localClass: null,
            localAuxClass: this.auxClass,
            localJsClass: this.jsClass,
            localModifiers: this.modifiers,
            localRel: null,
            localData: this.data,
        };
    },
    computed: {
    },
    setup() { // props, context
    },
    created() {
        this.localClass = this.compClass;

        if (this.localModifiers !== undefined) {
            this.localModifiers = this.compClass + "--" + this.localModifiers.split(",").join(" " + this.compClass + "--");
            this.localClass += " " + this.localModifiers;
        }

        // if (!this.submit && this.type !== 'button' && this.disabled) {
        //     this.localClass += " " + this.compClass + "--disabled";
        // }

        if (this.localAuxClass !== undefined) {
            this.localClass += " " + this.localAuxClass;
        }

        if (this.localJsClass !== undefined) {
            this.localClass += " " + this.localJsClass;
        }

        if (this.target === "_blank") {
            this.localRel = "noopener noreferrer";
        }
    },
    mounted() {
        //console.log('mounted');
    },
    methods: {
    },
});
</script>
