<template>
    <ion-page>
        <Header-Title :position="'center'" :title="$t('categories.ekitaldiak')" :principal="true" />
        <ion-content :fullscreen="true" :forceOverscroll="false">
            <ion-refresher slot="fixed" @ionRefresh="this.actAgenda($event)" closeDuration="360ms" pullFactor="0.8">
                <ion-refresher-content v-if="isIos" pulling-icon="Default"></ion-refresher-content>
                <ion-refresher-content v-else ></ion-refresher-content>
            </ion-refresher>
			<div class="page__body">
				<div class="spin"><ion-spinner></ion-spinner></div>
				<div class="wrp wrp-spin">
					<div v-if="this.agenda.length > 0">
						<div class="featured__event flex" v-bind:key="evento" v-for="evento in this.agenda">
						<Appointment-Excerpt :appointment="evento" />
						</div>
					</div>
				</div>
				<!-- fin wrp -->
				<div class="no__event flex__wrp align--center" style="opacity:0" v-if="this.agenda.length == 0">
					<div class="text--center">
						<h2 class="no__event__title title__l">{{ $t("ekitaldiak.noevents.title") }}</h2>
						<p class="no__event__text body__m">{{ $t("ekitaldiak.noevents.text") }}</p>
					</div>
				</div>
				<!-- fin no-events -->
			</div>
            <!-- fin page__body -->
            <ion-infinite-scroll v-if="this.agenda.length > 0" @ionInfinite="loadData($event)" threshold="100px" id="infinite-scroll" :disabled="isDisabled">
                <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading more data..."> </ion-infinite-scroll-content>
            </ion-infinite-scroll>
        </ion-content>
        <Galdetu-Float />
    </ion-page>
</template>

<script>
import { defineComponent, ref } from "vue";
import { IonContent, IonPage, IonRefresher, alertController, IonRefresherContent, IonInfiniteScroll, IonInfiniteScrollContent, isPlatform, IonSpinner} from "@ionic/vue";

import HeaderTitle from "@/components/HeaderTitle.vue";
import GaldetuFloat from "@/components/GaldetuFloat.vue";
import AppointmentExcerpt from "@/components/AppointmentExcerpt.vue";
//import Sdmenu from "@/components/menu.vue";

import { chevronDownCircleOutline } from "ionicons/icons";

// import "@/assets/js/ValidationRegister/init";
// import InputInfo from "@/assets/js/InputInfo/InputInfo";
// const { InputInfo } = require("@/assets/js/InputInfo/InputInfo");
// import { Preferences } from '@capacitor/preferences';

export default defineComponent({
    name: "RegistrationItem",
    components: {
        IonContent,
        IonPage,
        HeaderTitle,
        //Sdmenu,
        GaldetuFloat,
        AppointmentExcerpt,
        IonRefresher,
        IonRefresherContent,
        IonInfiniteScroll,
        IonInfiniteScrollContent,
        IonSpinner
    },
    props: {},
    data() {
        return {
            from: null,
            agenda: [],
            last:null
        };
    },

    setup() {

        const isDisabled = ref(false);
        const toggleInfiniteScroll = () => {
            isDisabled.value = !isDisabled.value;
        };

        return { chevronDownCircleOutline,  isDisabled, toggleInfiniteScroll };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.from = from;
        });
    },
    ionViewWillEnter() {
			this.$el.querySelector('.page__body').classList.add('opened')
		},
		ionViewDidEnter() {
			this.$el.querySelector('.page__body').classList.remove('opened');
		},
		ionViewDidLeave(){
			if(this.$el.querySelector('.wrp-spin')){
			this.$el.querySelector('.wrp-spin').classList.remove('wrp-spin');
		}
			if(this.$el.querySelector('.spin')){
				this.$el.querySelector('.spin').remove();
			}
		},
    async created() {
        // var that = this;
        // Preferences.get({ key: "agenda" }).then(function (response) {
        //     that.agenda = JSON.parse(response.value);
        // });
        //this.agenda = await this.getStorage("agenda", true);
         this.connect();
    },
    mounted() {
        // console.log(this.$auth);
    },
    computed: {
        language() {
            return this.$i18n.locale || document.documentElement.lang;
        },
        isAndroid() {
          return isPlatform("android");
        },
        isIos() {
          return isPlatform("ios");
        },
    },
    methods: {
        Next() {
            this.$router.push("/" + this.language + "/codigo");
        },
        galdetu() {},
        newTemplate(arg) {
            this.$router.push("/" + this.language + "/newtemplate?id=" + arg);
        },
        async presentAlert() {
            const alert = await alertController.create({
                cssClass: "my-custom-class",
                header: "ERROR",
                subHeader: "Lo sentimos ha ocurrido un error",
                message: "Error de Red no se ha podido conectar con el servidor.",
                buttons: ["OK"],
            });
            await alert.present();
            const { role } = await alert.onDidDismiss();
            console.log("onDidDismiss resolved with role", role);
        },
        async actAgenda(event) {
            await this.connect(true);
            event.target.complete();
        },
        async connect(arg){
            var that = this;
            if(arg){
                that.saveData('timestampAgenda'+this.$i18n.locale,'')
            } //si arg=true lo actualizamos
            console.log('connect');
            //const access_token = await this.getStorage("access_token", true);
            //const user = await this.getStorage("user", true);
            //const user_id=user.user_id;
            const timestamp = await this.getStorage("timestampAgenda"+this.$i18n.locale, true);
            let endpoint = this.$api_host + "/agenda/list/?lang=" + this.$i18n.locale + "&limit=20";

            if(timestamp ==null || parseInt(timestamp)+(1440000) < Date.now() || timestamp == ''){
                console.log('serverdata')
                that.$axiosPrivate.get(endpoint
                ).then((response) => {
                    if (response.status === 200) {
                        that.agenda = (response.data.length > 0)?response.data:'';
                        that.saveData('agenda'+this.$i18n.locale, response.data);
                        that.saveData('timestampAgenda'+this.$i18n.locale, Date.now());
                        if(that.agenda.length >0){
                            that.last=that.agenda[that.agenda.length -1].id_content;
                        }else{
                        that.$el.querySelector('.no__events').style.opacity=1;
                        }

                    }
                }).catch((error) => {

                    if (error.response.status === 409) {
                        this.openToastOptions(`Ha ocurrido un error`, "danger");
                    } else {
                        this.openToastOptions(error.response.data.error, "danger");
                    }

                });

            }else{
                that.loadData();
                console.log('loaddata')
            }

        },
        async saveData(key, data) {
            this.setStorage(key, data, true);
        },
        async loadData() {
            this.agenda = await this.getStorage("agenda"+this.$i18n.locale, true);
            this.$el.querySelector('.no__event').style.opacity=1;
        },
    }
});
</script>
