<template>
    <ion-page>
        <Header-Start />
        <ion-content :fullscreen="true" :forceOverscroll="false">
            <div class="change__page">
                <div class="form__content form__content--fixed">
                    <div class="wrp flex direction--column">
                        <div class="form__desktop__header">
                            <img src="../assets/img/pnv-logo-header.png" />
                        </div>
                        <div class="change__intro">
                            <div class="section__header">
                                <h1 class="section__title title__l text--center">{{ $t("change.title") }}</h1>
                            </div>
                            <div class="text">
                                <p class="body__s text--center" v-html="$t('change.text1')"></p>
                            </div>
                        </div>
                        <form
                            id="formChange"
                            name="change"
                            class="form form--change form--fixed flex__wrp direction--column"
                            action="."
                            method="post"
                            autocomplete
                        >
                            <fieldset>
                                <sd-form-input
                                    type="password"
                                    id="loginPass"
                                    name="password"
                                    :placeholder="$t('forms.fields.password')"
                                    autocomplete="new-password"
                                />
                                <sd-form-input
                                    type="password"
                                    id="loginPassRep"
                                    name="password-repeat"
                                    :placeholder="$t('forms.fields.passwordRepeat')"
                                    autocomplete="new-password"
                                />
                                <p class="form__message"></p>
                                <sd-form-input type="hidden" name="lang" :value="language"></sd-form-input>
                            </fieldset>

                            <div class="form__actions">
                                <sd-button modifiers="base" submit name="btnSubmit">{{ $t("forms.change.cta") }}</sd-button>
                                <router-link class="btn btn--secondary" v-bind:to="'/' + this.language + '/login'">
                                    {{ $t("forms.goto.home") }}
                                </router-link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { IonContent, IonPage, loadingController } from "@ionic/vue";
import { useRoute } from "vue-router";

// import { Plugins } from '@capacitor/core';
// const { SavePassword } = Plugins;

import HeaderStart from "@/components/HeaderStart.vue";
import SdButton from "@/components/Button.vue";
import SdFormInput from "@/components/FormInput.vue";

import Validation from "@/assets/js/Validation/Validation";

export default defineComponent({
    name: "LoginItem",
    components: {
        IonContent,
        IonPage,
        HeaderStart,
        SdButton,
        SdFormInput,
    },
    beforeRouteEnter(to, from, next) {
        console.log(to.params);
        if (!to.params.email || !to.params.user_id || !to.params.access_token) {
            console.log("Error");
            const lang = to.params.lang;
            window.location.replace("/" + lang + "/login");
        } else {
            next();
        }
    },
    data() {
        return {
            form: null,
            formData: [],
            userData: [],
            loading: null,
            passwordMinLength: 8,
        };
    },
    setup() {
        const route = useRoute();
        const { user_id, access_token, email } = route.params;
        return { user_id, access_token, email };
    },
    watch: {},
    created() {
        console.log("Created:", this.user_id, this.access_token, this.email);
    },
    mounted() {
        const _Change = (this.form = this.$el.querySelectorAll(".form--change")[0]);
        const _Constraints = {
            password: {
                presence: {
                    message: "^" + this.$t("forms.validate.password.required"),
                },
                length: {
                    minimum: this.passwordMinLength,
                    message: "^" + this.$t("forms.validate.password.min", { min: this.passwordMinLength }),
                },
            },
            "password-repeat": {
                presence: {
                    message: "^" + this.$t("forms.validate.password.required"),
                },
                length: {
                    minimum: this.passwordMinLength,
                    message: "^" + this.$t("forms.validate.password.min", { min: this.passwordMinLength }),
                },
                equality: {
                    attribute: "password",
                    message: "^" + this.$t("forms.validate.password.equality"),
                    comparator: function (v1, v2) {
                        return JSON.stringify(v1) === JSON.stringify(v2);
                    },
                },
            },
        };

        new Validation(_Change, _Constraints, {
            onFormSubmit: () => {
                //console.log(this.form);
                this.formData = JSON.parse(this.serializeJSON(_Change[0]));
                this.changePassword(this.formData.password);
            },
        });
    },
    computed: {
        language() {
            return this.$i18n.locale || document.documentElement.lang;
        },
    },
    methods: {
        async changePassword(pass) {
            this.loading = await loadingController.create({
                message: this.$t("change.sending"),
                duration: this.timeout,
            });
            await this.loading.present();

            let endpoint = this.$api_host + "/user/" + this.user_id + "/password/";
            this.$axiosPrivate
                .post(endpoint, {
                    email: this.email,
                    password: pass,
                })
                .then((response) => {
                    // let resp = response.data;
                    if (response.status === 200) {
                        this.loading.dismiss();
                        this.$router.push({ name: "Login" });
                        this.openToastOptions(`Password cambiado correctamente`, "success");
                    }
                })
                .catch((error) => {
                    this.loading.dismiss();
                    if (error.response.status === 401) {
                        this.openToastOptions(`El usuario ${this.email} no existe`, "warning");
                    } else {
                        this.openToastOptions(error.response.data.error, "danger");
                    }
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                });
        },
        gotoBack() {
            if (this.from.path != "/" && this.from.path != "/login-callback") {
                this.$router.go(-1);
            } else {
                this.$router.push("/" + this.language);
            }
        },
    },
});
</script>