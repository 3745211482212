<template>
		<div class="event__date text--center">
			<div class="event__date--number title__l">{{appointment.day}}</div>
			<div class="event__date--month body__l">{{$t(appointment.month)}}</div>
		</div>
		<div class="event__info">
			<div class="event__place pretitle">{{appointment.place}}</div>
			<div class="flex">
				<div class="event__name body__l">{{appointment.title}}</div>
				<div class="social__burukideak">
					<div class="social__participants" v-bind:key="bur" v-for="(bur) in appointment.tags" >
						<img class="ofc--img tt--ab" :src="bur.imgs">
					</div>
				</div>
			</div>
			<div class="event__time">
				<div class="time__icon"><img src='../assets/icon/clock.svg'></div>
				<div class="time__hour body__xs">{{appointment.time}}</div>
			</div>
		</div>

</template>
<script>
import { defineComponent } from "vue";



export default defineComponent({
    name: "AppointmentExcerpt",
    components: {

    },
    props:{
		appointment:Object,


    },
    data(){
		return {}
    },
    setup() {


    },
    computed: {
        language() {
             return this.$i18n.locale || document.documentElement.lang;
        }
    },
    methods:{

    }
 })

</script>
