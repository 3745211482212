import { createRouter,createWebHistory  } from "@ionic/vue-router";
// import { RouteRecordRaw } from 'vue-router';
// import HomePage from "../views/HomePage.vue";
import { routeGuard, routeGuard2} from "../auth";
import views from "../views";
let currentLang = 'eu-ES';
if (currentLang.indexOf("-") !== -1) currentLang = currentLang.split("-")[0];
if (currentLang.indexOf("_") !== -1) currentLang = currentLang.split("_")[0];
let locales = process.env.VUE_APP_LOCALES.split(",");
if (!locales.includes(currentLang)) currentLang = process.env.VUE_APP_DEFAULT_LOCALE;

const routes = [
    {
        path: "/",
        redirect: "/" + currentLang,
    },
    /*{
        path: "/:lang",
        component: views.LangRouter,
        children: [*/
            {
                path: "/:lang",
                name: "Home",
                component: views.Home,
                beforeEnter: routeGuard2,
                //component: () => import('@/views/Home.vue')
            },
            {
                path: "/:lang/onboarding",
                name: "OnBoarding",
                component: views.OnboardingApp,

            },
            {
                path: "/:lang/login",
                name: "Login",
                component: views.Login,
            },
            {
                path: "/:lang/registration",
                name: "Registration",
                component: views.Registration,
            },
            {
                path: "/:lang/passwordrecovery",
                name: "PasswordRecovery",
                component: views.PasswordRecovery,
            },
            {
                path: "/:lang/passwordchange",
                name: "PasswordChange",
                component: views.PasswordChange,
            },
            {
                path: "/:lang/codigo",
                name: "SMSCodeVerification",
                component: views.SMSCodeVerification,
            },
            {
                path: "/:lang/onboardinguser",
                name: "OnboardingUser",
                component: views.OnboardingUser,
                beforeEnter: routeGuard,
            },

            {
                path: "/:lang/noticias",
                name: "Noticias",
                component: views.News,
                beforeEnter: routeGuard,
            },
            {
                path: "/:lang/noticia/:id",
                name: "Noticia",
                component: views.NoticiaTemplate,
                beforeEnter: routeGuard,
            },
            {
                path: "/:lang/burukideak",
                name: "Burukideak",
                component: views.Burukideak,
                beforeEnter: routeGuard,
            },
             {
                path: "/:lang/burukide/:id",
                name: "BurukideTemplate",
                component: views.BurukideTemplate,
                beforeEnter: routeGuard,
            },
             {
                path: "/:lang/destacados",
                name: "Destacados",
                component: views.Destacados,
                beforeEnter: routeGuard,
            },
             {
                path: "/:lang/agenda",
                name: "Agenda",
                component: views.Agenda,
                beforeEnter: routeGuard,
            },
            {
                path: "/:lang/profile",
                name: "Profile",
                component: views.Profile,
                beforeEnter: routeGuard,
            },
            {
               path: "/:lang/galdetu",
               name: "chat",
               component: views.Galdetu,
               beforeEnter: routeGuard,
            },

            {
                path: "/:lang/chat/:id",
                name: "Chat",
                component: views.Chat,
                beforeEnter: routeGuard,
            },

        /*]
    },*/

    { path: "/:catchAll(.*)", component: views.NotFound },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export {
    router,
    currentLang,
    locales
};
