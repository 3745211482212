<template>
    <div class="menu__container">
        <Desktop-Menu/>
        <div class="wrp" v-if="auth">
            <div class="main__menu">
                <Menu-Item :nested="{icon:'home', href:'onboardinguser', title:'menu.home'}"/>
                <Menu-Item :nested="{icon:'burukideak', href:'burukideak', title:'menu.burukideak'}"/>
                <Menu-Item :nested="{icon:'nabarmenak', href:'destacados', title:'menu.nabarmenak'}"/>
                <Menu-Item :nested="{icon:'albisteak', href:'noticias', title:'menu.albisteak'}"/>
                <Menu-Item :nested="{icon:'ekitaldiak', href:'agenda', title:'menu.ekitaldiak'}"/>
				<Menu-Item :tabletOnly="'tablet--only'" :nested="{icon:'profila', href:'profile', title:'menu.profila'}"/>
            </div>
            <ion-button modifiers="" color="primary" expand="block" class="ion-btn galdetu__menu__btn" @click.prevent="galdetu">
                <img class="galdetu__menu__img" src='../assets/icon/chat-bot.svg'>
                <span class="galdetu__menu__text">{{$t("onboardingUser.galdetu.link")}}</span>
            </ion-button>
            <div class="lang__menu">
                <div v-if="language != 'eu' " id="lang_eu" class="lang__menu__item">
                    <ion-button fill="clear" class="ghost__btn menu__item__text" router-link="/eu/onboardinguser" router-direction="none" :router-animation="false">{{$t('menu.euskara')}}</ion-button>
                </div>
                <div v-if="language == 'eu' " class="lang__menu__item selected">
                    <span class="menu__item__text">{{$t('menu.euskara')}}</span>
                </div>
                <div v-if="language != 'es' " id="lang_es" class="lang__menu__item">
                    <ion-button fill="clear" class="ghost__btn" router-link="/es/onboardinguser" router-direction="none" :router-animation="false"><span class="menu__item__text">{{$t('menu.castellano')}}</span></ion-button>
                </div>
                <div v-if="language == 'es' " class="lang__menu__item selected">
                    <span class="menu__item__text">{{$t('menu.castellano')}}</span>
                </div>
            </div>
            <div class="menu__logout" modifiers="link" @click.prevent="logout">
                <span class="menu__logout__text">{{ $t("menu.logout") }}</span>
                <img class="menu__logout__img" src='../assets/icon/logout.svg'>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from "vue";
import { IonButton } from "@ionic/vue";
//import HeaderMenu from "@/components/HeaderMenu.vue";
import { useRoute, useRouter } from "vue-router";
import MenuItem from "@/components/MenuItem.vue";
import DesktopMenu from "@/components/DesktopMenu.vue";

export default defineComponent({
    name: "MenuMsinItem",
    components: {
        IonButton,
        MenuItem,
        DesktopMenu
    },
    data() {
        return {
            auth: this.$auth.isAuthenticated,
            desktop:false
        }
    },
    setup() {
		const router = useRouter();
		const route = useRoute();

		return {
		router,
		route,
		}
    },
    mounted(){
         document.querySelectorAll('.main__menu__link').forEach(function(e){
                e.addEventListener('click', function(){
                    setTimeout(function(){
                        document.querySelectorAll('.menu__header').forEach(function(e){
                            e.classList.remove('menu--opened');
                        })
                        document.querySelectorAll('.menu__container').forEach(function(e){
                            e.classList.remove('menu--opened');
                        })
                    },1000)

                })
            });
    },
    computed: {
        language() {
             return this.$i18n.locale || document.documentElement.lang;
        },
    },
    methods:{
		menuClose() {
			//menuController.close();
		},
		menuOpened(){

			//document.querySelector('ion-menu').style.display='block';
		},
        galdetu(){
            this.$router.push("/" + this.language+"/galdetu");
        },
		changeLanguage($event){
			if($event.target.lang != this.language){

			this.$router.push("/" + $event.target.lang + "/" + this.route.path.slice(4), 'none', 'animated');
            }
        },
    }
 })

</script>
