<template>
    <ion-page>
        <Header-Start :transparent="false"/>
        <ion-content :fullscreen="true" :forceOverscroll="false" :scroll-events="true" @ionScroll="headerScroll">
            <div class="registration__page form__page">
                <div class="form__content">
                    <div class="wrp flex direction--column">
						<div class="form__desktop__header">
							<img src="../assets/img/logo-pnv.svg" />
						</div>
                        <div class="registration__intro">
                            <div class="section__header">
                                <h1 class="registration__title title__l text--center">{{ $t("registration.title") }}</h1>
                            </div>
                        </div>
                        <form id="formRegister" name="register" class="form form--register" action="." method="post" novalidate>
                            <fieldset>
                                <sd-form-input type="text" id="registerName" name="name" :placeholder="$t('forms.fields.name')" autocomplete="name" required />
                                <sd-form-input type="text" id="registerLastName" name="last_name" :placeholder="$t('forms.fields.lastName1')" required />
                                <sd-form-input type="text" id="registerLastName2" name="last_name2" :placeholder="$t('forms.fields.lastName2')" required />
                                <sd-form-input type="text" id="registerNac" name="birth_date" :placeholder="$t('forms.fields.birthDate')" pattern="\d{4}-\d{2}-\d{2}" ref="birthDate" required />
                                <sd-form-input type="text" id="registerDni" name="reference" :placeholder="$t('forms.fields.reference')" required />
                                <div id="phoneSelector" class="flex prefix--select select--hide">
                                    <div class="basque__prefix prefix form__element select__element">
                                        <select name="phone_prefix">
                                            <option value="+34">+34</option>
                                            <option value="+33">+44</option>
                                        </select>
                                        <div class="select__arrow">
                                            <img src="../assets/icon/select-arrow.svg" />
                                        </div>
                                    </div>
                                    <div class="world__prefix prefix form__element">
                                        <input id="countryPrefix" type="text" value="+34" disabled />
                                    </div>
                                    <sd-form-input class="phone__number" type="text" id="registerPhone" name="phone_number" :placeholder="$t('forms.fields.phoneNumber')" required/>
                                </div>
                                <sd-form-input type="email" id="registerEmail" name="email" :placeholder="$t('forms.fields.email')" autocomplete="username" required />
                                <sd-form-input type="password" id="registerPass" name="password" :placeholder="$t('forms.fields.password')" autocomplete="new-password" required/>

                                <sd-form-checkbox id="registerLopd" name="terms_accepted" class="terms__check" required v-if="this.language == 'es'">
                                    <p>
                                        {{ $t("forms.legal.term") }} <a :href="$t('forms.legal.url')" @click.prevent="showTerms">{{ $t("forms.legal.tos") }}</a>
                                    </p>
                                    <!-- <span v-html="t('forms.legal')"></span> -->
                                </sd-form-checkbox>
                                <sd-form-checkbox id="registerLopd" name="terms_accepted" class="terms__check" required v-else>
                                    <p>
                                        <a :href="$t('forms.legal.url')" @click.prevent="showTerms">{{ $t("forms.legal.tos") }}</a>{{ $t("forms.legal.term") }}
                                    </p>
                                    <!-- <span v-html="t('forms.legal')"></span> -->
                                </sd-form-checkbox>
                                <sd-form-input type="hidden" name="terms_version" value="1"></sd-form-input>

                                <p class="form__message"></p>
                                <sd-form-input type="hidden" name="lang" :value="language"></sd-form-input>
                            </fieldset>
                            <div class="form__actions">
                                <sd-button modifiers="base" submit name="submit">{{ $t("forms.register.cta") }}</sd-button>
                                <router-link class="btn btn--secondary" v-bind:to="'/' + this.language + '/login'">
                                    {{$t("forms.goto.login.text")}}{{$t("forms.goto.login.cta")}}
                                </router-link>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </ion-content>

    </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { IonContent, IonPage, loadingController, modalController } from "@ionic/vue";
// import { Keyboard } from '@capacitor/keyboard';

import HeaderStart from "@/components/HeaderStart.vue";

import SdButton from "@/components/Button.vue";
import SdFormInput from "@/components/FormInput.vue";
import SdFormCheckbox from "@/components/FormCheckbox.vue";
import SMSCodeVerification from "@/views/SMSCodeVerification.vue";
import Alderdikide from "@/components/Alderdikide.vue";
// import LegalTerms from "@/views/LegalTerms.vue";

import Validation from "@/assets/js/Validation/Validation";
import countriesData from "@/assets/countries.json";

export default defineComponent({
    name: "RegistrationItem",
    components: {
        IonContent,
        IonPage,
        HeaderStart,
        SdButton,
        SdFormInput,
        SdFormCheckbox,
    },
    data() {
        return {
            formData: [],
            userData: [],
            from: null,
            modal: null,
            countries: countriesData.countries,
            passwordMinLength: 8,
            ageRequired: 16,
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.from = from;
        });
    },
    setup() {},
    // async created() {
    //     await Keyboard.setScroll({ isDisabled: true });
    // },
    mounted() {
        let birthDateElement = this.$refs.birthDate;
        let birthDateInput = birthDateElement.$el.querySelector("input");
        // birthDateInput.addEventListener('focus', (evt) => this.changeDateInput(evt));
        birthDateInput.addEventListener('focus', this.changeDateInput);

        const _Registration = this.$el.querySelectorAll(".form--register");
        const _Constraints = {
            name: {
                presence: {
                    message: "^" + this.$t("forms.validate.name.required"),
                },
            },
            last_name: {
                presence: {
                    message: "^" + this.$t("forms.validate.lastName.required"),
                },
            },
            birth_date: {
                presence: {
                    message: "^" + this.$t("forms.validate.birthDate.required"),
                },
                datetime: {
                    dateOnly: true,
                    latest: this.$moment().utc().subtract(this.ageRequired, "years"),
                    message: "^" + this.$t("forms.validate.birthDate.datetime", {age: this.ageRequired}),
                }
            },
            reference: {
                presence: {
                    message: "^" + this.$t("forms.validate.reference.required"),
                },
                nif: {
                    message: "^" + this.$t("forms.validate.reference.format"),
                },
            },
            phone_number: {
                presence: {
                    message: "^" + this.$t("forms.validate.phoneNumber.required"),
                },
                phone: {
                    message: "^" + this.$t("forms.validate.phoneNumber.format"),
                },
            },
            email: {
                presence: {
                    message: "^" + this.$t("forms.validate.email.required"),
                },
                email: {
                    message: "^" + this.$t("forms.validate.email.format"),
                },
            },
            password: {
                presence: {
                    message: "^" + this.$t("forms.validate.password.required"),
                },
                length: {
                    minimum: this.passwordMinLength,
                    message: "^" + this.$t("forms.validate.password.min", {min: this.passwordMinLength}),
                },
            },
            terms_accepted: {
                presence: {
                    message: "^" + this.$t("forms.validate.termsAccepted.required"),
                },
                inclusion: {
                    within: [true],
                    message: "^" + this.$t("forms.validate.termsAccepted.required"),
                },
            },
        };
        new Validation(_Registration, _Constraints, {
            onFormSubmit: () => {
                this.formData = JSON.parse(this.serializeJSON(_Registration[0]));
                if (this.$app_name) this.formData["plataforma"] = this.$app_name;
                // this.openModal(Alderdikide, {email: this.formData.email});
                this.openModal(SMSCodeVerification, { phone_prefix: this.formData.phone_prefix, phone_number: this.formData.phone_number });
            },
        });
    },
    computed: {
        language() {
            return this.$i18n.locale || document.documentElement.lang;
        },
    },
    methods: {
        async openModal(component, data) {
            let componentProps = {
                closeMe: this.closeModal,
                passDataBack: this.passDataBackModal,
            };
            if (data) componentProps.passData = data;
            this.modal = await modalController.create({
                component: component,
                keyboardClose: false,
                componentProps: componentProps,
            });
            // FirebaseAnalytics.logEvent({
            //     name: "modal_open",
            //     params: {
            //         name: "invitations",
            //     },
            // });
            return this.modal.present();
        },
        passDataBackModal(data) {
            if (data.verification) {
                this.saveFormData(this.formData);
            }
        },
        closeModal() {
            let args = Array.prototype.slice.call(arguments);
            if (args[0] === true) {
                this.closeToast();
            }
            if (this.modal) {
                this.modal.dismiss().then(() => {
                    this.modal = null;
                    this.modalOpen = false;
                });
            }
        },
        async saveFormData(data) {
            const loading = await loadingController.create({
                message: this.$t("registration.saving"),
                duration: this.timeout,
            });

            await loading.present();
            let endpoint = this.$api_host + "/user/register/";
            this.$axiosPrivate
                .post(endpoint, data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    let resp = response.data;
                    let user = {
                        user_id: resp.user_id,
                        email: data.email,
                        name: data.name,
                        last_name: data.last_name,
                        last_name2: data.last_name2,
                        phone_prefix: data.phone_prefix,
                        phone_number: data.phone_number,
                        postal_code: data.postal_code,
                        country: data.country,
                        is_local: data.is_local,
                        reference: data.reference,
                        birth_date: data.birth_date,
                        terms_version: data.terms_version,
                    };

                    this.setStorage("user", user, true);
                    this.setStorage("access_token", resp.access_token, true);
                    this.setStorage("access_token_expires", resp.access_token_expires, true);

                    loading.dismiss();
                    // console.log("Logged => Go to dashboard");
                    this.$router.push("/" + this.language + "/onboardinguser");
                })
                .catch((error) => {
                    loading.dismiss();
                    if (error.response.status === 409) {
                        this.openToastOptions(this.$t("registration.error_registered", {email: data.email}), "danger");
                    } else if (error.response.status === 403) {
                        // No Alderdikide: Redirigir al usuario a la App Zukesan
                        this.openModal(Alderdikide, {email: this.formData.email});
                        //this.openToastOptions(this.$t("registration.error_alderdikide", {email: data.email}), "warning");
                    } else {
                        this.openToastOptions(error.response.data.error, "danger");
                    }
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                });
        },
        check($event) {
            this.$nextTick(() => {
                // console.log($event.target);
                if ($event.target.getAttribute("id") == "registerOut") {
                    this.$el.querySelector("#countrySelector").classList.remove("select--hide");
                    this.$el.querySelector("#phoneSelector").classList.remove("select--hide");
                } else {
                    this.$el.querySelector("#countrySelector").classList.add("select--hide");
                    this.$el.querySelector("#phoneSelector").classList.add("select--hide");
                }
            });
        },
        // gotoBack() {
        //     if (this.from.path != "/" && this.from.path != "/login-callback") {
        //         this.$router.go(-1);
        //     } else {
        //         this.$router.push("/" + this.language);
        //     }
        // },
        countryPrefix($event) {
            var value = $event.target.options[$event.target.selectedIndex].getAttribute("data-prefix");
            this.$el.querySelector("#countryPrefix").value = value;
        },
        headerScroll() {
            if (this.$el.querySelector(".form__content").getBoundingClientRect().top < -20) {
                //this.$el.querySelector(".page__header").classList.add("bg--header");
                this.$el.querySelector(".page__header").classList.add("border--header");
            } else {
                //this.$el.querySelector(".page__header").classList.remove("bg--header");
                this.$el.querySelector(".page__header").classList.remove("border--header");
            }
        },
        changeDateInput(evt) {
            if (evt != null) {
                evt.preventDefault();
            }
            evt.target.type = "date";
            if (!evt.target.value) evt.target.value = this.$moment().subtract(this.ageRequired, "years").format("YYYY-MM-DD");

            let birthDateElement = this.$refs.birthDate;
            let birthDateInput = birthDateElement.$el.querySelector("input");
            birthDateInput.removeEventListener('focus', this.changeDateInput);

            evt.target.blur();
            setTimeout(() => {
                evt.target.focus();
            }, 70);
        },
    },
});
</script>
<style>
    ion-content{
       --keyboard-offset: 0 !important;
    }
</style>
