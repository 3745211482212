<template>
    <ion-page>
        <Header-Start navClose :closeMe="closeMe" :transparent="false" />
        <ion-content class="sms__page feedback" :fullscreen="true" :forceOverscroll="false">
            <div class="form__content">
                <div class="wrp flex direction--column">
                    <div class="sms__intro">
                        <div class="sms__img"><img class="" src="../assets/img/check.svg" /></div>
                        <div class="section__header">
                            <h1 class="section__title title__l text--center">{{ $t("verification.title") }}</h1>
                        </div>
                        <div class="text">
                            <p v-if="passData.email" class="body__s text--center">{{ $t("verification.text2", { email: passData.email }) }}</p>
                            <p v-else class="body__s text--center">{{ $t("verification.text1", { phoneNumber: passData.phone_prefix + " " + numberHide(passData.phone_number) }) }}</p>
                        </div>
                    </div>

                    <form id="formVerification" name="verification" class="form form--verification form--fixed flex__wrp direction--column text--center" action="." method="post" novalidate>
                        <div>
                            <fieldset>
                                <label for="otc" class="sms-code__intro body__m">{{ $t("verification.introducir") }}</label>
                                <div class="codepad">
                                    <sd-form-input type="text" name="verification_code1" auxClass="autotab" inputmode="numeric" pattern="[0-9]*" id="otc" autocomplete="one-time-code" ref="sms_input" />
                                    <sd-form-input type="text" name="verification_code2" auxClass="autotab" inputmode="numeric" pattern="[0-9]*"/>
                                    <sd-form-input type="text" name="verification_code3" auxClass="autotab" inputmode="numeric" pattern="[0-9]*"/>
                                    <sd-form-input type="text" name="verification_code4" auxClass="autotab" inputmode="numeric" pattern="[0-9]*"/>
                                    <sd-form-input type="text" name="verification_code5" auxClass="autotab" inputmode="numeric" pattern="[0-9]*" :maxlength="1"/>
                                </div>
                                <sd-form-input type="text" id="verificationCode" name="verification_code" autocomplete="one-time-code" inputClass="input--hidden" inputmode="numeric" pattern="[0-9]*" required/>

                                <p class="form__message"></p>
                            </fieldset>

                            <vue-countdown v-if="countDownReloadExpires" class="body__m" :time="countDownReloadExpires * 1000" :transform="transformSlotProps" @end="onCountdownEnd" v-slot="{ minutes, seconds }">
                                {{ $t("verification.renew") }}: {{ minutes }}:{{ seconds }}
                            </vue-countdown>
                            <sd-button v-else modifiers="link" auxClass="sms-valid" @click.prevent="sendVerificationCode(true)">
                                {{ $t("verification.newCode")}}
                            </sd-button>
                            <div class="event"></div>
                        </div>

                        <div class="form__actions">
                            <sd-button modifiers="base" submit name="btnSubmit">{{ $t("forms.sms.cta") }}</sd-button>
                            <a v-if="passData.phone_number" :href="'/' + this.language + '/registration'" class="btn btn--secondary" @click.prevent="closeMe">{{ $t("forms.goto.phone.text") }}{{ $t("forms.goto.phone.cta") }}</a>
                        </div>
                    </form>

                </div>
            </div>
        </ion-content>

    </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { IonContent, IonPage } from "@ionic/vue";
// import { SmsRetriever } from '@awesome-cordova-plugins/sms-retriever';

import SdButton from "@/components/Button.vue";
import SdFormInput from "@/components/FormInput.vue";
import HeaderStart from "@/components/HeaderStart.vue";
import VueCountdown from '@chenfengyuan/vue-countdown';

import Validation from "@/assets/js/Validation/Validation";

export default defineComponent({
    name: "SMSCodeVerification",
    components: {
        IonContent,
        IonPage,
        SdButton,
        SdFormInput,
        HeaderStart,
        VueCountdown,
    },
    props: {
        closeMe: {
            type: Function,
            default: () => {},
        },
        passData: {},
        passDataBack: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            from: null,
            name: null,
            verificationCodeExpires: null,
            verificationReloadExpires: null,
            countDownReloadExpires: null,
            phonePrefix: null,
            phoneNumber: null,
            focused: false,
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.from = from;
        });
    },
    setup() {
        // const smsRetriever = SmsRetriever;
        // return { smsRetriever };
    },
    mounted() {
        // console.log(this.passData);
        this.sendVerificationCode();
        // this.autoTab();
        this.codePadSetup();
        const _Verification = this.$el.querySelectorAll(".form--verification");
        // const locale = document.documentElement.lang;
        const _Constraints = {
            "verification_code": {
                presence: {
                    message: "^" + this.$t("forms.validate.verification_code.required"),
                },
                format: {
                    pattern: "[0-9]+",
                    message: "^" + this.$t("forms.validate.verification_code.format"),
                },
                length: {
                    is: 5,
                    message: "^" + this.$t("forms.validate.verification_code.length", {length: 5}),
                },
            },
        };
        new Validation(_Verification, _Constraints, {
            onFormSubmit: () => {
                let verificationCode = document.getElementById("verificationCode").value;
                this.checkVerificationCode(verificationCode);
            }
        });

        // if (isPlatform("android") && !isPlatform("mobileweb")) {
        //     // this.smsRetriever.getAppHash()
        //     //     .then((res) => alert(res)) // so2hChXAYMF
        //     //     .catch((error) => console.error(error));
        //     this.smsRetriever.startWatching()
        //         .then((res) => {
        //             const smsMessage = res.Message;
        //             const matches = smsMessage.match(/\d{5}/);
        //             if (matches) {
        //                 this.pasteCode(matches[0]);
        //             }
        //         })
        //         .catch((error) => console.error(error));
        // }

        this.focusInput();
    },
    computed: {
        language() {
            return this.$i18n.locale || document.documentElement.lang;
        },
    },
    methods: {
        focusInput() {
            let input = this.$refs.sms_input.$el.querySelector("input");
            if (!this.focused) {
                setTimeout(() => {
                    this.focused = true;
                    input.focus();
                }, 1000);
            }
        },
        numberHide(number) {
            return number.substring(0, 1) + '*****' + number.substr(-3);
        },
        setTimeToExpire(endtime) {
            const total = endtime - this.$moment().unix();
            this.countDownReloadExpires = total;
        },
        transformSlotProps(props) {
            const formattedProps = {};
            Object.entries(props).forEach(([key, value]) => {
                formattedProps[key] = value < 10 ? `0${value}` : String(value);
            });
            return formattedProps;
        },
        onCountdownEnd() {
            this.countDownReloadExpires = null;
        },
        sendVerificationCode(renew = false) {
            this.clearInputs();
            let endpoint = this.$api_host + "/user/sendCode/";
            this.$axiosPrivate.post(endpoint, this.passData, {
                headers: {
                    // 'Content-Type': 'application/json'
                    'Content-Type': 'multipart/form-data'
                }
            }).then(async (response) => {
                //console.log(response.data);
                if (response.status === 200) {
                    this.verificationCodeExpires = response.data["verification_code_expires"];
                    this.verificationReloadExpires = response.data["verification_reload_expires"];
                    this.phonePrefix = response.data["phone_prefix"];
                    this.phoneNumber = response.data["phone_number"];

                    if (this.verificationReloadExpires) {
                        this.setStorage("verificationReloadExpires", this.verificationReloadExpires);
                        if (renew) {
                            this.openToastOptions(this.$t("verification.send.success"), "success");
                        }
                    } else {
                        this.verificationReloadExpires = await this.getStorage("verificationReloadExpires");
                        this.openToastOptions(this.$t("verification.send.wait"), "warning");
                    }
                    this.setTimeToExpire(this.verificationReloadExpires);
                } else {
                    // this.openToastOptions(this.$t("participants.add.success"), "error");
                }

            }).catch((error) => {
                // let resp = error.response.data;
                // console.log(resp);
                if (error.response.status === 409) {
                    this.closeMe();
                    this.openToastOptions(error.response.data.error, "danger");
                }
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            });
        },
        checkVerificationCode(verificationCode) {
            let endpoint = this.$api_host + "/user/verifyCode/";
            let data = {
                phone_prefix: this.passData.phone_prefix || this.phonePrefix,
                phone_number: this.passData.phone_number || this.phoneNumber,
                verification_code: verificationCode
            };
            if (this.phonePrefix !== null && this.phoneNumber !== null) {
                data.requires_token = true;
                data.email = this.passData.email;
            }
            this.$axiosPrivate.post(endpoint, data, {
                headers: {
                    // 'Content-Type': 'application/json'
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                //console.log(response.data);
                if (response.status === 200) {
                    // Verified
                    let dataBack = { verification: true };
                    if (response.data.user_id) { dataBack.user_id = response.data.user_id; }
                    if (response.data.access_token) {
                        this.setStorage("access_token", response.data.access_token, true);
                        this.setStorage("access_token_expires", response.data.access_token_expires, true);
                        dataBack.access_token = response.data.access_token;
                    }
                    this.passDataBack(dataBack);
                    this.closeMe(true);
                }

            }).catch((error) => {
                // let resp = error.response.data;
                // console.log(error);
                if (error?.response?.status === 404) {
                    switch (error.response.data?.error_code) {
                        case 1203:
                        case 1204:
                            // var phone = data.phone_prefix + data.phone_number;
                            // this.openToastOptions(this.$t("verification.error.code_" + error.response.data.error_code, {phone: phone}), "danger");
                            this.openToastOptions(this.$t("verification.error.code_" + error.response.data.error_code), "danger");
                            break;
                        default:
                            this.openToastOptions(error.response.data.error_code + ": " + error.response.data.error, "danger");
                            break;
                    }
                } else {
                    this.openToastOptions(error.response.data.error, "danger");
                }
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            });
        },
        clearInputs() {
            const codeSliceInputs = document.querySelectorAll(".autotab input");
            codeSliceInputs.forEach((input) => {
                input.value = "";
            });
            document.querySelector("input.input--hidden").value = "";
        },
        splitNumber(evt) {
            let data = evt.data || evt.target.value; // Chrome doesn't get the e.data, it's always empty, fallback to value then.
            if (!data) return; // Shouldn't happen, just in case.
            if (data.length === 1) return; // Here is a normal behavior, not a paste action.

            this.popuNext(evt.target, data);
            //for (i = 0; i < data.length; i++ ) { ins[i].value = data[i]; }
        },
        popuNext(el, data) {
            const codeSliceInputs = document.querySelectorAll(".autotab input");
            const codeSliceNodes = Array.prototype.slice.call(codeSliceInputs);
            const idx = codeSliceNodes.indexOf(el);
            const nextInput = codeSliceInputs[idx + 1];
            el.value = data[0]; // Apply first item to first input
            data = data.substring(1); // remove the first char.
            if ( nextInput && data.length ) {
                // Do the same with the next element and next data
                this.popuNext(nextInput, data);
            }
        },
        pasteCode(code) {
            // console.log({code});
            const codeInput = document.querySelector("input.input--hidden");
            codeInput.value = code;

            const codeSliceInputs = document.querySelectorAll(".autotab input");
            codeSliceInputs.forEach((input, idx) => {
                const value = codeInput.value;
                input.value = value[idx] || "";
            });
        },
        codePadSetup() {
            // const invalidKeys = ["Tab", "Shift", "Control", "Alt", "Meta", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"];
            // const invalidKeyCodes = [16, 9, 224, 17, 18];
            // const backKeyCodes = [8, 37];
            const codeInput = document.querySelector("input.input--hidden");
            const codeSliceInputs = document.querySelectorAll(".autotab input");
            const firstCodeInput = codeSliceInputs[0];

            codeSliceInputs.forEach((input, idx) => {
                const prevInput = codeSliceInputs[idx - 1];
                const nextInput = codeSliceInputs[idx + 1];

                input.addEventListener('keyup', (evt) => {
                    // Break if Shift, Tab, CMD, Option, Control.
                    if (evt.keyCode === 16 || evt.keyCode == 9 || evt.keyCode == 224 || evt.keyCode == 18 || evt.keyCode == 17) {
                        return;
                    }

                    // On Backspace or left arrow, go to the previous field.
                    if ( (evt.keyCode === 8 || evt.keyCode === 37) && prevInput && prevInput.tagName === "INPUT" ) {
                        prevInput.select();
                    } else if (evt.keyCode !== 8 && nextInput) {
                        nextInput.select();
                    }
                    // console.log(input.nextElementSibling);

                    // If the target is populated to quickly, value length can be > 1
                    if ( evt.target.value.length > 1 ) {
                        this.splitNumber(evt);
                    }

                    codeInput.value = Array.from(codeSliceInputs).map(item => item.value).reduce((total, value) => total + value);
                });

                input.addEventListener('focus', () => {
                    if ( input === firstCodeInput ) return;

                    if ( firstCodeInput.value == '' ) {
                        firstCodeInput.focus();
                    }

                    if ( prevInput?.value == '' ) {
                        prevInput.focus();
                    }
                });

                // input.addEventListener('change', (evt) => {
                //     setTimeout(() => {
                //         const eventText = document.querySelector(".event");
                //         eventText.innerHTML = "Change" + evt.target.value;
                //         console.log("Change",evt.target.value);
                //         if ( evt.target.value.length > 1 ) {
                //             this.splitNumber(evt);
                //         }
                //     }, 100);
                // });
                input.addEventListener('input', (evt) => {
                    setTimeout(() => {
                        // const eventText = document.querySelector(".event");
                        // eventText.innerHTML = "Input" + evt.target.value;
                        // console.log("Input",evt.target.value);
                        if ( evt.target.value.length > 1 ) {
                            this.splitNumber(evt);
                            codeInput.value = Array.from(codeSliceInputs).map(item => item.value).reduce((total, value) => total + value);
                        }
                    }, 50);
                });
                // input.addEventListener('paste', (evt) => {
                //     setTimeout(() => {
                //         const eventText = document.querySelector(".event");
                //         eventText.innerHTML = "Paste" + evt.target.value;
                //         console.log("Paste",evt.target.value);
                //         if ( evt.target.value.length > 1 ) {
                //             this.splitNumber(evt);
                //         }
                //     }, 100);
                // });
                // input.addEventListener('blur', (evt) => {
                //     setTimeout(() => {
                //         const eventText = document.querySelector(".event");
                //         eventText.innerHTML = "Blur" + evt.target.value;
                //         console.log("Blur",evt.target.value);
                //         if ( evt.target.value.length > 1 ) {
                //             this.splitNumber(evt);
                //         }
                //     }, 100);
                // });
            });

            // codeInput.addEventListener("change", () => {
            //     const value = codeInput.value;
            //     codeSliceInputs.forEach((input, idx) => {
            //         input.value = value[idx] || "";
            //     });
            // });

            ['change', 'input'].forEach((event) => {
                codeInput.addEventListener(event, () => {
                    const value = codeInput.value;
                    codeSliceInputs.forEach((input, idx) => {
                        input.value = value[idx] || "";
                    });
                });
            });
        },
        setValor() {
            const codeSliceInputs = document.querySelectorAll(".autotab input");
            const firstCodeInput = codeSliceInputs[0];

            firstCodeInput.value = "12345";
        },
    },
});
</script>
