<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <div id="container" class="wrp" v-show="!loading">
                <!-- <div>
                    <strong>PNV APP</strong>
                </div>
                <ion-button modifiers="" color="primary" expand="block" class="btn-base" @click.prevent="this.next()">{{ $t("home.boton") }}</ion-button>
                <sd-button modifiers="link" @click.prevent="this.next()">{{ $t("home.boton") }}</sd-button>
                <router-link v-bind:to="'/' + language + '/onboardingUser'">Home</router-link> -->
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import { useIonRouter, IonContent, IonPage, loadingController } from "@ionic/vue";

import { defineComponent } from "vue";
// import SdButton from "@/components/Button.vue";
import { Storage } from "@ionic/storage";
export default defineComponent({
    name: "HomeItem",
    components: {
        IonContent,
        IonPage,
    },
    install: {
        async createStorage() {
            console.log("storage");
            const store = new Storage();
            await store.create();
        },
    },
    props: [],
    data() {
        return {
            loading: true,
        };
    },
    setup() {
        const ionRouter = useIonRouter();
        return { ionRouter };
    },
    watch: {},
    computed: {
        language() {
            return this.$i18n.locale || document.documentElement.lang;
        },
    },
    mounted() {
        this.homeDispatcher();
    },
    methods: {
        next() {
            this.$router.push("/" + this.language + "/onboarding");
        },
        async homeDispatcher() {
            const loader = await loadingController.create({
                message: "",
                //cssClass: "my-custom-class",
                //duration: 1000,
            });

            this.loading = true;
            await loader.present();
            // const storage = new Storage();
            // storage.create();
            const onboardingAppViewed = this.getStorage("onboardingAppViewed");
            // const onboardingAppViewedNow = Preferences.get("onboardingAppViewedNow");

            onboardingAppViewed.then((res) => {
                console.log({res});
                if (res) {
                    // Onboarding App visto
                    if (this.$auth.isAuthenticated) {
                        // console.log("User is logged");
                        // this.$router.push("/" + this.language + "/onboardinguser", 'root', 'replace');
                        this.ionRouter.navigate(`/${this.language}/onboardinguser`, 'root', 'replace');
                        loader.dismiss().then(() => {
                            this.loading = false;
                        });
                    }
                }
                else {
                    // this.$router.push("/" + this.language + "/onboarding");
                    this.ionRouter.navigate(`/${this.language}/onboarding`, 'root', 'replace');
                    loader.dismiss().then(() => {
                        this.loading = false;
                    });
                }
            });
        },
    },
});
</script>
