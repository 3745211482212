<template>
    <ion-page>
        <Header-Start :transparent="false"/>
        <ion-content :fullscreen="true" :forceOverscroll="false">
            <div class="login__page form__page">
                <div class="form__content form__content--fixed">
                    <div class="wrp flex direction--column">
						<div class="form__desktop__header">
							<img src="../assets/img/logo-pnv.svg" />
						</div>
                        <div class="login__intro">
                            <div class="section__header">
                                <h1 class="section__title title__l text--center">{{ $t("login.title") }}</h1>
                            </div>
                            <div class="text">
                                <p class="body__s text--center" v-html="$t('login.text1')"></p>
                            </div>
                        </div>
                        <form id="formLogin" name="login" class="form form--login form--fixed flex__wrp direction--column" action="." method="post" autocomplete>
                            <fieldset>
                                <sd-form-input type="email" id="loginEmail" name="email" :placeholder="$t('forms.fields.email')" autocomplete="username" />
                                <sd-form-input type="password" id="loginPass" name="password" :placeholder="$t('forms.fields.password')" autocomplete="current-password" />
                                <div class="recover">
                                    <sd-button modifiers="link" @click.prevent="$router.push('/' + language + '/passwordrecovery')">
                                        {{ $t("forms.goto.recover.cta")}}
                                    </sd-button>
                                </div>
                                <p class="form__message"></p>
                                <sd-form-input type="hidden" name="lang" :value="language"></sd-form-input>
                            </fieldset>
                            <div class="form__actions">
                                <sd-button modifiers="base" submit name="btnSubmit">{{ $t("forms.login.cta") }}</sd-button>
                                <router-link class="btn btn--secondary" v-bind:to="'/' + this.language + '/registration'">
                                    {{ $t("forms.goto.register.cta") }}
                                </router-link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
// import { store } from '@/store/';
import { defineComponent } from "vue";
import { useIonRouter, IonPage, IonContent, loadingController, modalController, useKeyboard, isPlatform } from "@ionic/vue";
// import { Plugins } from "@capacitor/core";
// const { SavePassword } = Plugins;
import { SavePassword } from 'capacitor-ios-autofill-save-password';

import SdButton from "@/components/Button.vue";
import SdFormInput from "@/components/FormInput.vue";
import HeaderStart from "@/components/HeaderStart.vue";
import Alderdikide from "@/components/Alderdikide.vue";

import Validation from "@/assets/js/Validation/Validation";

export default defineComponent({
    name: "LoginItem",
    components: {
        IonPage,
        IonContent,
        SdButton,
        SdFormInput,
        HeaderStart,
    },
    data() {
        return {
            form: null,
            formData: [],
            userData: [],
            loading: null,
        };
    },
    setup() {
        const ionRouter = useIonRouter();
        const { isOpen, keyboardHeight } = useKeyboard();
        return { ionRouter, isOpen, keyboardHeight };
    },
    watch: {
        keyboardHeight: function () {
            console.log(this.keyboardHeight);
            console.log(this.isOpen);
        },
    },
    mounted() {
        const _Login = (this.form = this.$el.querySelectorAll(".form--login")[0]);
        const _Constraints = {
            email: {
                presence: {
                    message: "^" + this.$t("forms.validate.email.required"),
                },
                email: {
                    message: "^" + this.$t("forms.validate.email.format"),
                },
            },
            password: {
                presence: {
                    message: "^" + this.$t("forms.validate.password.required"),
                },
            },
        };

        new Validation(_Login, _Constraints, {
            onFormSubmit: () => {
                this.formData = JSON.parse(this.serializeJSON(_Login[0]));
                this.authLogin(this.formData.email, this.formData.password);
            },
        });
    },
    computed: {
        language() {
            return this.$i18n.locale || document.documentElement.lang;
        },
    },
    methods: {
        async openModal(component, data) {
            let componentProps = {
                closeMe: this.closeModal,
                passDataBack: this.passDataBackModal,
            };
            if (data) componentProps.passData = data;
            this.modal = await modalController.create({
                component: component,
                keyboardClose: false,
                componentProps: componentProps,
            });
            // FirebaseAnalytics.logEvent({
            //     name: "modal_open",
            //     params: {
            //         name: "invitations",
            //     },
            // });
            return this.modal.present();
        },
        passDataBackModal(data) {
            if (data.verification) {
                this.saveFormData(this.formData);
            }
        },
        closeModal() {
            let args = Array.prototype.slice.call(arguments);
            if (args[0] === true) {
                this.closeToast();
            }
            if (this.modal) {
                this.modal.dismiss().then(() => {
                    this.modal = null;
                    this.modalOpen = false;
                });
            }
        },
        async getUserData(user_id) {
            let endpoint = this.$api_host + "/user/" + user_id + "/get/";
            this.$axiosPrivate
                .get(endpoint)
                .then((response) => {
                    if (response.status === 200) {
                        this.setStorage("user", response.data, true);
                        this.loading.dismiss();
                        this.ionRouter.navigate(`/${this.language}/onboardinguser`, 'root', 'replace');
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 409) {
                        this.openToastOptions(`El usuario ${user_id} no existe`, "danger");
                    } else {
                        this.openToastOptions(error.response.data.error, "danger");
                    }
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                });
        },
        async saveCredentials(user, password) {
            if (isPlatform("ios") && !isPlatform("mobileweb")) {
                await SavePassword.promptDialog({
                    username: user,
                    password: password,
                })
                .then(() => console.log('promptDialog success'))
                .catch((err) => console.error('promptDialog failure', err));
            }
            if (window.PasswordCredential) {
                const passwordCredential = new window.PasswordCredential({
                    id: user,
                    password: password,
                });
                navigator.credentials.store(passwordCredential);
            }
        },
        async authLogin(user, pass) {
            this.loading = await loadingController.create({
                message: this.$t("login.sending"),
                duration: this.timeout,
            });

            await this.loading.present();

            let endpoint = this.$api_host + "/user/login/";
            this.$axiosPrivate
                .post(endpoint, {
                    email: user,
                    password: pass,
                })
                .then((response) => {
                    let resp = response.data;

                    if (resp.user_id) {
                        this.saveCredentials(user, pass);
                        this.setStorage("access_token", resp.access_token, true);
                        this.setStorage("access_token_expires", resp.access_token_expires, true);
                        this.getUserData(resp.user_id);
                    } else {
                        this.loading.dismiss();
                    }
                })
                .catch((error) => {
                    this.loading.dismiss();
                    if (error.response.status === 401) {
                        this.openToastOptions(this.$t("login.error", {user: user}), "warning");
                    } else if (error.response.status === 403) {
                        // No Alderdikide: Redirigir al usuario a la App Zukesan
                        this.openModal(Alderdikide, {email: this.formData.email});
                    } else {
                        this.openToastOptions(error.response.data.error, "danger");
                    }
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                });
        },
        // gotoBack() {
        //     if (this.from.path != "/" && this.from.path != "/login-callback") {
        //         this.$router.go(-1);
        //     } else {
        //         this.$router.push("/" + this.language);
        //     }
        // },
    },
});
</script>
