<template>
    <ion-page>
        <ion-header :translucent="false">
            <div class="page__header">
                <div class="wrp">

                </div>
            </div>
        </ion-header>
        <ion-content class="feedback" :fullscreen="true" :forceOverscroll="false">
            <div class="page__body">
                <div class="wrp">
					ERROR 404
					<router-link to="/" class="btn">Volver a la home</router-link>

                </div>

            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { IonPage, IonHeader, IonContent } from "@ionic/vue";



export default defineComponent({
    name: "404Item",
    components: { IonPage, IonContent, IonHeader },
    props: [],
    data() {
        return {};
    },
    watch: {},
    created() {},
    computed: {
        language() {
            return this.$i18n.locale || document.documentElement.lang;
        },
    },
    mounted() {},
    methods: {},
});
</script>
