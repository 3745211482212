<template>
    <ion-page>
        <Header-Title :title="$t('general.back')" />

        <ion-content :fullscreen="true" :forceOverscroll="false">
            <div class="page__body">
                <div class="spin"><ion-spinner></ion-spinner></div>

                <div class="wrp wrp-spin">
                    <div class="area__container">
                        <div class="main__burukide">
                            <div class="flex align--center">
                                <div class="burukide__img">
                                    <img class="ofc--img tt--ab" :src="burukide.imgs" />
                                </div>
                                <div>
                                    <div class="burukide__title">
                                        {{ burukide.title }}
                                    </div>
                                    <div class="burukide__subtitle">
                                        {{ burukide.claim }}
                                    </div>
                                    <div class="burukide__tags__wrp">
                                        <div class="burukide__tag" v-bind:key="tag" v-for="tag in burukide.tags">
                                            {{ tag.acr }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="burukide__tabs">
                                <div class="burukide__tabs__switcher">
                                    <div class="tab1 tab__title tab__title--active" @click.prevent="this.tabs(1)">
                                        {{ $t("burukideak.biografia") }}
                                    </div>
                                    <div v-if="noticias.length > 0" class="tab2 tab__title" @click.prevent="this.tabs(2)">
                                        {{ $t("burukideak.albisteak") }}
                                    </div>
                                    <div class="tab__switch"></div>
                                </div>
                                <div class="tab__wrp">
                                    <div class="tab__content1 tab__content">
                                        <div class="text" v-html="html"></div>
                                    </div>
                                    <div class="tab__content2 tab__content tab--back">
                                        <div v-if="noticias.length > 0">
                                            <div class="related__new" v-bind:key="noti" v-for="noti in noticias">
                                                <RelatedNew :noti="noti" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>

        <Galdetu-FloatId :burukideId="bId" />
    </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { IonPage, IonContent, IonSpinner } from "@ionic/vue";
import GaldetuFloatId from "@/components/GaldetuFloat2.vue";
import HeaderTitle from "@/components/HeaderTitle.vue";

import RelatedNew from "@/components/RelatedNew.vue";
// import { useRoute, useRouter } from "vue-router";

export default defineComponent({
    name: "BurukideTemplateItem",
    components: {
        IonPage,
        IonContent,
        HeaderTitle,
        GaldetuFloatId,
        RelatedNew,
        IonSpinner,
    },
    props: {},
    data() {
        return {
            from: null,
            burukide: [],
            html: [],
            noticias: [],
            burukideAll: [],
            bId: null,
        };
    },
    setup() {
//         const router = useRouter();
//         const route = useRoute();
//
//         return {
//             router,
//             route,
//         };
    },
    // beforeRouteEnter(to, from, next) {
    //     next((vm) => {
    //         vm.from = from;
    //     });
    // },
    ionViewWillEnter() {
        this.$el.querySelector(".page__body").classList.add("opened");
    },
    ionViewDidEnter() {

    },
    ionViewDidLeave() {
        if (this.$el.querySelector(".wrp-spin")) {
            this.$el.querySelector(".wrp-spin").classList.remove("wrp-spin");
        }
        if (this.$el.querySelector(".spin")) {
            this.$el.querySelector(".spin").remove();
        }
    },
    watch: {},
    created() {
        this.bId = this.$route.params.id;
    },
    mounted() {
        var that = this;
        var con = that.connect();
        return { con };
    },
    computed: {
        language() {
            return this.$i18n.locale || document.documentElement.lang;
        },
    },
    methods: {
        // Next() {
        //     this.$router.push("/" + this.language + "/codigo");
        // },
        // galdetu() {},
        // gotoBack() {
        //     if (this.from.path != "/" && this.from.path != "/login-callback") {
        //         this.$router.go(-1);
        //     } else {
        //         this.$router.push("/" + this.language);
        //     }
        // },
        tabs(arg) {
            document.querySelectorAll(".tab__title").forEach((tab) => {
                tab.classList.remove("tab__title--active");
            });
            document.querySelector(".tab" + arg).classList.add("tab__title--active");
            document.querySelectorAll(".tab__content").forEach((tab) => {
                tab.classList.add("tab--back");
            });
            document.querySelector(".tab__content" + arg).classList.remove("tab--back");
        },
        async connect(arg) {
            if (arg) {
                this.saveData("timestampBurukide" + this.bId + this.$i18n.locale, "");
            } //si arg=true lo actualizamos
            const timestamp = await this.getStorage("timestampBurukide" + this.bId + this.$i18n.locale, true);
            const dataB = await this.getStorage("burukide" + this.bId + this.$i18n.locale, true);
            let endpoint = this.$api_host + "/burukide/get/?burukideId=" + this.bId + "&lang=" + this.$i18n.locale;
            // console.log(dataB);

            if (timestamp == null || parseInt(timestamp) + 1440000 < Date.now() || timestamp == "" || dataB == null) {
                this.$axiosPrivate
                    .get(endpoint)
                    .then((response) => {
                        if (response.status === 200) {
                            this.burukide = response.data[0];
                            this.html = this.burukide.html.descripcion;
                            this.noticias = response.data.news;
                            this.saveData("burukide" + this.bId + this.$i18n.locale, response.data);
                            this.saveData("timestampBurukide" + this.bId + this.$i18n.locale, Date.now());
                            this.$el.querySelector(".page__body").classList.remove("opened");
                            console.log('server')
                        }
                    })
                    .catch((error) => {
                        if (error.response.status === 409) {
                            this.openToastOptions(`Ha ocurrido un error`, "danger");
                        } else {
                            this.openToastOptions(error.response.data.error, "danger");
                        }
                    });
            } else {
                this.loadData();
                // console.log("loaddata");
            }
        },
        async saveData(key, data) {
            this.setStorage(key, data, true);
        },
        async loadData() {
            var bd = await this.getStorage("burukide" + this.bId + this.$i18n.locale, true);
            this.burukideAll = bd;
            this.burukide = bd[0];
            this.html = this.burukide.html.descripcion;
            this.noticias = bd.news;
            this.$el.querySelector(".page__body").classList.remove("opened");
        },
    },
});
</script>
