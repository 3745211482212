import { reactive } from "vue";

export const store = reactive({
    loading: true,
    isAuthenticated: false,
    appName: "",
    user: {},
    popupOpen: false,
    logedOut: false,
    error: null,
});