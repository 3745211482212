<template>
	<router-link class="related__new__link" v-bind:to="'/'+this.language+'/noticia/'+noti.id_content">
		<div>
			<div class="new__title body__l" v-html="noti.titulo"></div>
			<div class="new__excerpt body__s" v-html="noti.argumento"></div>
		</div>
		<div class="new__img">
			<img class="ofc--img" v-bind:src="noti.imagen" />
		</div>
	</router-link>
	<Sharebar :date="noti.fecha" :dateControl="true" :thumbs="false" :txalosControl="false" :url="noti.urlNoticia"/>
</template>
<script>
import { defineComponent } from "vue";
import Sharebar from "@/components/Sharebar.vue";


export default defineComponent({
	name: "RelatedNew",
	components: {
		Sharebar
	},
	props:{
		noti:Object


	},
	setup() {

	},
	computed: {
		language() {
			return this.$i18n.locale || document.documentElement.lang;
		}
	},
	methods:{

	}
 })

</script>
