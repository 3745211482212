<template>
    <router-link class="burukide__link flex__wrp align--center" v-bind:to="'/'+this.language+'/chat/'+mgs.burukideId">
    <div class="mgs--wrp" v-bind:class = "( mgs.mensaje.respuesta == 1)?'checked':''">
        <div class="flex__wrp align--center" >
            <div class="message__title body__m">{{mgs.burukide_title}}</div>
			<div class="message__date flex align--center">
				<span class="body__xs">{{mgs.time}}</span>
				<svg class="message__arrow" viewBox="0 0 16 16">
					<path d="M6.10824 12.9775C6.32406 12.9775 6.50114 12.9001 6.64502 12.7562L10.8895 8.61133C11.0776 8.42318 11.1717 8.22949 11.1717 7.99154C11.1717 7.75358 11.0832 7.55436 10.8895 7.37175L6.64502 3.22135C6.49561 3.07747 6.31852 3 6.10824 3C5.68213 3 5.3335 3.3431 5.3335 3.76921C5.3335 3.97949 5.42204 4.17871 5.57699 4.33366L9.34001 7.99154L5.57699 11.6439C5.42204 11.7988 5.3335 11.998 5.3335 12.2083C5.3335 12.6344 5.68213 12.9775 6.10824 12.9775Z"/>
                </svg>
            </div>
        </div>
        <div class="flex__wrp align--center">
            <div class="message__excerpt body__m"><span v-html="mgs.mensaje.mensaje"></span></div>
            <div class="message__status">
                <div class="new__messages" v-if="mgs.news > 0">
                    <span class="pretitle">{{mgs.news}}</span>
                </div>
            </div>
        </div>
    </div>
</router-link>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
    name: "ProfileExcerpt",
    components: {

    },
    props:{
		mgs:Object,

    },
    data(){
        return{

        }
    },
    setup() {
    },
    computed: {
        language() {

             return this.$i18n.locale || document.documentElement.lang;
        },

    },
    methods:{
    }
 })
</script>
