<template>
    <ion-app :class="namePage">
		<HeaderTablet/>
        <ion-split-pane when="md" content-id="main" >
            <Sdmenu/>
            <div id="main" v-bind:class="[auth ? 'aunthend' : '']" >
                <ion-split-pane  when="lg" content-id="main2" >
					<div id="main2">
						<ion-router-outlet :appIsActive="appIsActive"> </ion-router-outlet>
					</div>
					<div>
						<Profile/>
					</div>
				</ion-split-pane>
            </div>
        </ion-split-pane>
        <feedback v-if="!isConnected" :navClose="false" :mainTitle="noConnectedTitle" :description="noConnectedText" />
    </ion-app>
</template>

<script>
// import { Plugins } from "@capacitor/core";
import { IonApp, IonRouterOutlet, isPlatform, IonSplitPane } from "@ionic/vue";
import { defineComponent } from "vue";
import { App } from '@capacitor/app';
import { Network } from '@capacitor/network';
import { Device } from '@capacitor/device';
import Feedback from "@/views/Feedback.vue";
import Sdmenu from "@/components/menu.vue";
import HeaderTablet from "@/components/HeaderTablet.vue";
import Profile from "@/components/ProfileD.vue";
import { useRouter } from 'vue-router';

export default defineComponent({
    name: "App",
    components: {
        IonApp,
        IonRouterOutlet,
        Feedback,
        IonSplitPane,
        Sdmenu,
		Profile,
		HeaderTablet
    },
    data() {
        return {
            isConnected: true,
            noConnectedTitle: this.$t("network.offline.title"),
            noConnectedText: "<p>" + this.$t("network.offline.text") + "</p>",
            appIsActive: true,
            auth: this.$auth.isAuthenticated,
            desktop:false,
			namePage:'',
        };
    },
    setup() {
		const router = useRouter();
		return { router };
	},
    created() {
        Network.addListener("networkStatusChange", (status) => {
            this.isConnected = status.connected;
        });
    },
	watch:{
		$route (to, from){
			console.log('watch');
			console.log(to);
			console.log(from);
			this.namePage=to.name;
		}
	},
    async mounted() {
			console.log('mountapp');
			const devi = await Device.getInfo();
			if(devi.platform=='web'){
			this.desktop = true;
		}
			this.namePage = this.router.currentRoute._value.name;
		
        if ((isPlatform("ios") || isPlatform("android")) && !isPlatform("mobileweb")) {
            const info = await Device.getInfo();
            console.log(info);
            localStorage.setItem("deviceID", info.uuid);
            // FirebaseAnalytics.setUserId({
            //     userId: info.uuid,
            // });
        } else {
          const info = await Device.getInfo();
          console.log(info);
            // FirebaseAnalytics.initializeFirebase(this.$firebaseConfig);
        }
        App.addListener("appStateChange", async (status) => {
            // Listener para detectar cuando la app pasa a activa
            this.appIsActive = status.isActive;
            if (this.appIsActive) {
                // this.loadTabs();
            }
        });
    },
});
</script>
