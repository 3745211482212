<template>
    <ion-page>
        <Header-Start :langMenu="false" />
        <ion-content class="feedback" :fullscreen="true" :forceOverscroll="false">
            <div class="page__body">
                <div class="wrp">
                    <div class="page__nav">
                        <sd-button :disabled="!navBack" auxClass="nav__back" modifiers="link">
                            <svg-icon icon="chevronleft"></svg-icon>
                        </sd-button>
                        <sd-button :disabled="!navClose || navBack" auxClass="nav__close" modifiers="link" @click.prevent="closeMe">
                            <svg-icon icon="close"></svg-icon>
                        </sd-button>
                    </div>

                    <sd-text :mainTitle="mainTitle" :title="title" :description="description" modifiers="center">
                        <ion-progress-bar v-if="progressBar" type="indeterminate"></ion-progress-bar>
                    </sd-text>

                    <div v-if="sat" class="sat">
                        <span class="sat__text">{{ t("sat.text") }}</span>
                        <sd-button auxClass="sat__btn" modifiers="pill">
                            <span>{{ t("sat.cta") }}</span>
                            <svg-icon icon="dialog"></svg-icon>
                        </sd-button>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import { defineComponent } from "vue";
//import { useI18n } from "vue-i18n";
import { IonPage, IonContent, IonProgressBar } from "@ionic/vue";
import SdText from "@/components/Text.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import SdButton from "@/components/Button.vue";
// import Actionbar from "@/components/Actionbar.vue";
//import HeaderLogo from "@/components/HeaderLogo.vue";
import HeaderStart from "@/components/HeaderStart.vue";

export default defineComponent({
    name: "FeedbackItem",
    components: { IonPage, IonContent, HeaderStart, SdText, SvgIcon, SdButton, IonProgressBar },
    props: {
        navBack: {
            type: Boolean,
            default: false,
        },
        navClose: {
            type: Boolean,
            default: true,
        },
        mainTitle: {
            type: String,
            default: "Feedbak Title",
        },
        title: String,
        description: {
            type: String,
            default: "<p>Feedback description</p>",
        },
        progressBar: {
            type: Boolean,
            default: false,
        },
        sat: {
            type: Boolean,
            default: false,
        },
        closeMe: {
            type: Function,
            default: () => {},
        },
    },
    // data() {
    //     return {
    //         localMainTitle: this.mainTitle,
    //     };
    // },
    // setup() {
    //     const { t, locale } = useI18n();
    //     return { t, locale };
    // },
    computed: {
        language() {
            return this.$i18n.locale || document.documentElement.lang;
        },
    },
});
</script>
