<template>
	<ion-page>
		<Header-Title :title="$t('general.back')" />
		<ion-content :fullscreen="true" :forceOverscroll="false">
			<div class="page__body">
				<div class="spin"><ion-spinner></ion-spinner></div>
				<div class="wrp wrp-spin">
					<div class="area__container">
						<div class="main__new">
							<h1 class="new__title title__l" v-html="noticia.titulo"></h1>
							<div class="new__excerpt body__m" v-html="noticia.argumento"></div>
							<div class="new__date pretitle" v-html="noticia.fecha"></div>
							<div class="new__img">
								<img class="ofc--img tt--ab" v-bind:src="noticia.imagen" />
							</div>
							<div class="new__video" v-if="noticia.video">
								<a class="youtube--link" v-bind:href="noticia.video" target="_blank">
									<span class="video__icon"><img src='../assets/icon/play-button.svg'></span>
									<span class="video__text">{{$t('general.video')}}</span>
								</a>
							</div>
							<div class="new__text text" v-html="noticia.texto"></div>
							<Sharebar :txalosNumber="noticia.likes" :id="noticia.id_content" :url="noticia.urlNoticia"/>
							<div class="related__burukideak" v-if="noticia.burukides">
								<div class="featured__burukide" v-bind:key="buru" v-for="(buru) in noticia.burukides">
									<router-link class="burukide__link" v-bind:to="'/'+this.language+'/burukide/'+buru.info.id_content">
										<div class="flex">
											<div class="burukide__img">
												<img class="ofc--img tt--ab" :src="buru.imgs">
											</div>
											<div>
												<div class="burukide__pretitle">{{$t('albisteak.participantes')}}</div>
												<div class="burukide__title">{{buru.info.title}}</div>
												<div class="burukide__subtitle body__s">{{buru.info.claim}}</div>
											</div>
										</div>
									</router-link>
								</div>
							</div>
						</div>
						<div class="related__news">
							<div class="sp--line"></div>
							<div class="section__header" v-if="noticia.rel!=''">
								<div class="section__title title__l">{{$t('albisteak.related.title')}}</div>
							</div>
							<div class="related__new" v-bind:key="noti" v-for="(noti) in noticia.rel">
								<RelatedNew :noti="noti"/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Sdfooter/>
		</ion-content>
		<Galdetu-Float />
    </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { IonContent, IonPage, IonSpinner} from "@ionic/vue";
import HeaderTitle from "@/components/HeaderTitle.vue";
import GaldetuFloat from "@/components/GaldetuFloat.vue";
import Sharebar from "@/components/Sharebar.vue";
import Sdfooter from "@/components/footer.vue";
import RelatedNew from "@/components/RelatedNew.vue";
//import "@/assets/js/ValidationRegister/init";
//import InputInfo from "@/assets/js/InputInfo/InputInfo";
//const { InputInfo } = require("@/assets/js/InputInfo/InputInfo");
//import { Preferences } from '@capacitor/preferences';
import { useRoute, useRouter } from "vue-router";
//import axios from "axios";
export default defineComponent({
    name: "RegistrationItem",
    components: {
        IonContent,
        IonPage,
        HeaderTitle,
		GaldetuFloat,
		Sharebar,
		Sdfooter,
		RelatedNew,
		IonSpinner
    },
    props:{

    },
    data(){
		return{
			from: null,
			noticia:[]
		}
    },
    setup() {
		const router = useRouter();
		const route = useRoute();

		return {
		router,
		route,
		}
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.from = from;
        });
    },
		ionViewWillEnter() {
			this.$el.querySelector('.page__body').classList.add('opened')
		},
		ionViewDidEnter() {
			this.$el.querySelector('.page__body').classList.remove('opened');
		},
		ionViewDidLeave(){
			if(this.$el.querySelector('.wrp-spin')){
			this.$el.querySelector('.wrp-spin').classList.remove('wrp-spin');
		}
			if(this.$el.querySelector('.spin')){
				this.$el.querySelector('.spin').remove();
			}
		},
    async created() {


	},
    mounted() {
        this.connect();

		},
    computed: {
        language() {
             return this.$i18n.locale || document.documentElement.lang;
        }
    },
    methods:{
		Next(){
			this.$router.push("/" + this.language+"/codigo");
		},
		galdetu(){

		},
		gotoBack() {
			if (this.from.path != "/" && this.from.path != "/login-callback") {
			this.$router.go(-1);
			} else {
			this.$router.push("/" + this.language);
			}
		},
        async connect(arg){
            var that = this;
            console.log('connect');
            var nId=this.route.params.id;
						var lang= this.$i18n.locale;
            if(arg){
                that.saveData('timestampNoticia'+nId+lang,'')
            } //si arg=true lo actualizamos
            //const access_token = await this.getStorage("access_token", true);
            //const user = await this.getStorage("user", true);
            //const user_id=user.user_id;
            const timestamp = await this.getStorage("timestampNoticia"+nId+lang, true);
            let endpoint = this.$api_host + "/noticias/get/?noticiaId="+nId+"&lang=" + lang ;

            if(timestamp ==null || parseInt(timestamp)+(1440000) < Date.now() || timestamp == ''){

                that.$axiosPrivate.get(endpoint
                ).then((response) => {
                    if (response.status === 200) {
                        that.noticia = response.data;
                        that.saveData('noticia'+nId+lang, response.data);
                        that.saveData('timestampNoticia'+nId+lang, Date.now());
                        that.$el.querySelector('.page__body').classList.remove('page-hide');

                    }
                }).catch((error) => {

                    if (error.response.status === 409) {
                        this.openToastOptions(`Ha ocurrido un error`, "danger");
                    } else {
                        this.openToastOptions(error.response.data.error, "danger");
                    }

                });

            }else{
                that.loadData();
                that.$el.querySelector('.page__body').classList.remove('page-hide');
                console.log('loaddata')
            }

        },
        async saveData(key, data) {
            this.setStorage(key, data, true);
        },
        async loadData() {
            var nId=this.route.params.id;
						var lang = this.$i18n.locale;
            this.noticia = await this.getStorage('noticia'+nId+lang, true);
        },

	}
});
</script>
