<template>
  <ion-fab class="float__btn" vertical="center" horizontal="end" slot="fixed">
      <router-link v-bind:to="'/'+this.language+'/chat/'+burukideId">
        <ion-fab-button class="galdetu__btn">
          <img src='../assets/icon/chat-bot.svg'>
        </ion-fab-button>
      </router-link>
  </ion-fab>
</template>
<script>
import { IonFab, IonFabButton } from "@ionic/vue";
export default {
    name: "GaldetuFloat",
    components: {
      IonFab, IonFabButton
    },
    props: {
      burukideId:{
      type: String,

      }
    },
    computed: {
        language() {
             return this.$i18n.locale || document.documentElement.lang;
        }
    },
};
</script>
