<template>
    <ion-header>
        <div class="page__header title--header bg--header border--header" v-bind:class="{ 'principal' : principal === true}">
            <div class="menu__back" @click.prevent="this.gotoBack()">
              <img src='../assets/icon/back.svg'>
            </div>
            <div class="header__title" @click.prevent="this.gotoBack()" v-bind:class="{ 'text--center' : position === 'center'}">
              <h1>{{title}}</h1>
            </div>
            <div class="menu__forward"></div>
        </div>
    </ion-header>
</template>
<script>
import { defineComponent } from "vue";
import { IonHeader } from "@ionic/vue";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  name: "HeaderTitle",
  components: {
      IonHeader,
  },
  props:{
    position: String,
    title:String,
	principal:{
		type:Boolean,
		default:false
	}
  },
  setup() {
		const router = useRouter();
		const route = useRoute();

		return {
		router,
		route,
		}
  },
    methods:{

		gotoBack() {

            this.$router.go(-1);

		},
    }
});
</script>
