<template>
    <div v-if="type !== 'hidden'" :class="localClass">
        <label v-if="$slots.default" :for="localID"><slot></slot></label>
        <input
            :id="localID"
            :type="type"
            :name="name"
            :placeholder="placeholder"
            :required="required"
            :value="value"
            :disabled="disabled"
            :readonly="readonly"
            :autocomplete="autocomplete"
            :autocapitalize="localAutocapitalize"
            :spellcheck="localSpellcheck"
            :inputmode="inputmode"
            :pattern="pattern"
            :class="inputClass"
            :maxlength="maxlength"
        />
    </div>
    <input v-else :id="localID" :type="type" :name="name" :value="value" :class="auxClass" />
</template>

<script>
import PasswordToggler from "@/assets/js/PasswordToggler/PasswordToggler";
export default {
    name: "FormInput",
    props: {
        auxClass: String,
        jsClass: String,
        modifiers: String,
        id: String,
        inputClass: String,
        type: {
            type: String,
            default: "text",
        },
        name: {
            type: String,
            default: "formSubmit",
        },
        placeholder: String,
        value: String,
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        autocomplete: String,
        inputmode: String,
        pattern: String,
        maxlength:{
            type: Number,
            default:100
        }
    },
    data() {
        return {
            compClass: "form__element",
            localClass: null,
            localAuxClass: this.auxClass,
            localJsClass: this.jsClass,
            localModifiers: this.modifiers,
            localID: this.id || "input" + (Math.floor(Math.random() * (99 - 10)) + 10),
            localAutocapitalize: null,
            localSpellcheck: null,
        };
    },
    computed: {},
    setup() {
        // props, context
    },
    created() {
        this.localClass = this.compClass;


        if (this.localModifiers !== undefined) {
            this.localModifiers = this.compClass + "--" + this.localModifiers.split(",").join(" " + this.compClass + "--");
            this.localClass += " " + this.localModifiers;
        }

        if (this.localAuxClass !== undefined) {
            this.localClass += " " + this.localAuxClass;
        }

        if (this.localJsClass !== undefined) {
            this.localClass += " " + this.localJsClass;
        }

        if (this.type !== "hidden") {
            this.localAutocapitalize = "off";
            this.localSpellcheck = "false";
        }
    },
    mounted() {
        //console.log('mounted');
        if (this.type === "password") {
            const passwordInputs = this.$el.querySelector('input[type="password"]');
            new PasswordToggler(passwordInputs);
        }
    },
    methods: {},
};
</script>
