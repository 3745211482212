<template>
    <router-link class="" v-bind:to="'/'+this.language+'/burukide/'+burukide.id_content" routerDirection="forward" color="dark">
		<div class="slide__img">
			<img class="ofc--img tt--ab" :src="burukide.imgs"/>
		</div>
		<div class="slide__text text text--center">
			<div class="slide__title body__s">{{burukide.title}}</div>
			<div class="slide__subtitle body__s">{{burukide.claim}}</div>
		</div>
	</router-link>

</template>
<script>
import { defineComponent } from "vue";



export default defineComponent({
    name: "BurukideExcerpt",
    components: {

    },
    props:{
		burukide:Object


    },
    data(){
        return {}
    },
    setup() {

    },
    computed: {
        language() {
             return this.$i18n.locale || document.documentElement.lang;
        }
    },
    methods:{

    }
 })

</script>
