<template>
    <ion-page>
        <Header-Title :position="'center'" :title="$t('categories.burukideak')" :principal="true" />
        <ion-content :fullscreen="true" :forceOverscroll="false">
            <div class="page__body">
                <div class="spin"><ion-spinner></ion-spinner></div>

                <div class="wrp-spin wrp">
                    <div class="area__container">
                        <div class="featured__burukide" v-bind:key="buru" v-for="buru in this.burukideak">
                            <router-link class="burukide__link flex__wrp align--center" v-bind:to="'/' + this.language + '/burukide/' + buru.id_content">
                                <div class="flex align--center">
                                    <div class="burukide__img">
                                        <img class="ofc--img tt--ab" :src="buru.imgs" />
                                    </div>
                                    <div>
                                        <div class="burukide__title">{{ buru.title }}</div>
                                        <div class="burukide__subtitle">{{ buru.claim }}</div>
                                        <div class="burukide__tags__wrp">
                                            <div class="burukide__tag" v-bind:key="tag" v-for="tag in buru.tags">
                                                {{ tag.acr }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="burukide__next">
                                    <img src="../assets/icon/forward.svg" />
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>

            </div>
        </ion-content>
    </ion-page>
</template>
<script>
import { defineComponent } from "vue";
import { IonPage, IonContent, IonSpinner } from "@ionic/vue";
import HeaderTitle from "@/components/HeaderTitle.vue";

export default defineComponent({
    name: "BurukideakItem",
    components: {
        IonPage,
        IonContent,
        HeaderTitle,
        IonSpinner,
    },
    props: {},
    data() {
        return {
            from: null,
            burukideak: [],
        };
    },
    setup() {},
    // beforeRouteEnter(to, from, next) {
    //     next((vm) => {
    //         vm.from = from;
    //     });
    // },
    ionViewWillEnter() {
        this.$el.querySelector(".page__body").classList.add("opened");
    },
    ionViewDidEnter() {
        this.$el.querySelector(".page__body").classList.remove("opened");
    },
    ionViewDidLeave() {
        this.$el.querySelector(".wrp").classList.remove("wrp-spin");
        if (this.$el.querySelector(".spin")) {
            this.$el.querySelector(".spin").remove();
        }
    },
    async created() {
		this.connect();
        //this.burukideak = await this.getStorage("burukideak" + this.$i18n.locale, true);
    },
    mounted() {},
    computed: {
        language() {
            return this.$i18n.locale || document.documentElement.lang;
        },
    },
    watch: {
        // $route(to, from) {
        //     console.log("roue");
        //     const toDepth = to.path.split("/").length;
        //     const fromDepth = from.path.split("/").length;
        //     this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
        // },
    },
    methods: {
		async loadData() {
		var that = this;
		that.burukideak = await this.getStorage("burukideak"+ this.$i18n.locale, true);
		
	},
	async connect(arg){
		var that = this;
		if(arg){
			that.saveData('timestamp','')
		}
		//const access_token = await this.getStorage("access_token", true);
		//const user = await this.getStorage("user", true);
		//const user_id=user.user_id;
		const timestamp = await this.getStorage("timestamp", true);

		let endpoint = this.$api_host + "/onboard/list/?lang=" + this.$i18n.locale ;
	
		if(timestamp ==null || parseInt(timestamp)+(144000) < Date.now() || timestamp == ''){
	
			that.$axiosPrivate.get(endpoint
			).then((response) => {
				if (response.status === 200) {

					that.burukideak = response.data.burukideak;

					that.saveData('noticiasOn'+ this.$i18n.locale, response.data.noticias);
					that.saveData('burukideak'+ this.$i18n.locale, response.data.burukideak);
					that.saveData('agendaOn'+ this.$i18n.locale, response.data.agenda);
					if(response.data.encuesta.length >0){
						that.saveData('encuesta'+ this.$i18n.locale, response.data.encuesta[0].encuesta[0]);
					}else{
						that.saveData('encuesta'+ this.$i18n.locale,'');
					}
					that.saveData('destacadosOn'+ this.$i18n.locale, response.data.destacados);

					console.log('serverdata')
					//document.querySelector('.chat__messages__container').scrollTo(0,document.querySelector('.chat__messages__container').scrollHeight);
	
				}
			}).catch((error) => {
	
				if (error.response.status === 409) {
					this.openToastOptions(`Ha ocurrido un error`, "danger");
				} else {
					this.openToastOptions(error.response.data.error, "danger");
				}
	
			});
	
		}else{
			that.loadData();
			console.log('loaddata')
		}
	
	
	},
    },
});
</script>