import LangRouter from "./LangRouter.vue";
import Home from "./Home.vue";
import NotFound from "./404.vue";
import Registration from "./Registration.vue";
import Login from "./Login.vue";
import PasswordRecovery from "./PasswordRecovery.vue";
import PasswordChange from "./PasswordChange.vue";
import OnboardingApp from "./OnboardingApp.vue";
import SMSCodeVerification from "./SMSCodeVerification.vue";
import OnboardingUser from "./OnboardingUser.vue";
import Galdetu from "./Galdetu.vue";
import News from "./News.vue";
import NoticiaTemplate from "./NoticiaTemplate.vue";
import Burukideak from "./Burukideak.vue";
import BurukideTemplate from "./BurukideTemplate.vue";
import Destacados from "./Destacados.vue";
import Agenda from "./Agenda.vue";
import Profile from "./Profile.vue";
import Chat from "./Chat.vue";


export default {
    LangRouter,
    Home,
    NotFound,
    Registration,
    Login,
    PasswordRecovery,
    PasswordChange,
    OnboardingApp,
    SMSCodeVerification,
    OnboardingUser,
    Galdetu,
    News,
    NoticiaTemplate,
    Burukideak,
    BurukideTemplate,
    Destacados,
    Agenda,
    Profile,
    Chat
};
