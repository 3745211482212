<template>
    <ion-page>
        <Header-Menu :MenuClose="true" :mensajes="mensajes" :title="$t('categories.hasiera')" :cl="'home-menu'" />
        <ion-content :fullscreen="true" :forceOverscroll="false" :scroll-events="true" @ionScroll="scrolling">
            <ion-refresher slot="fixed" @ionRefresh="this.actOnboard($event)" closeDuration="360ms" pullFactor="0.8">
                <ion-refresher-content></ion-refresher-content>
            </ion-refresher>

            <div class="home page__body">
                <div class="spin"><ion-spinner></ion-spinner></div>
                <div class="wrp-spin">
                    <section class="home__hero">
                        <div class="hero__img">
                            <img class="ofc--img tt--ab" src="../assets/img/home/home-hero.jpg" />
                        </div>
                        <div class="hero__content tt--ab">
                            <div>
                                <h2 class="hero__title">
                                    <span>{{ $t("onboardingUser.hero.title1") }}</span>
                                    <span>{{ $t("onboardingUser.hero.title2") }}</span>
                                </h2>
                                <router-link v-bind:to="'/' + language + '/galdetu'">
                                    <div class="hero__link">
                                        {{ $t("onboardingUser.hero.link") }}<span><img src="../assets/icon/hero-arrow.svg" /></span>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </section>
                    <section class="home__galdetu">
                        <div class="section--sp">
                            <div class="wrp">
                                <div class="section__header">
                                    <div class="section__title title__l text--center">{{ $t("onboardingUser.galdetu.title") }}</div>
                                </div>
                                <div class="text text--center">
                                    <p class="body__m">{{ $t("onboardingUser.galdetu.text1") }}</p>
                                    <p class="body__m">{{ $t("onboardingUser.galdetu.text2") }}</p>
                                </div>
                                <ion-button modifiers="" color="primary" expand="block" class="ion-btn" @click.prevent="galdetu">{{
                                    $t("onboardingUser.galdetu.link")
                                }}</ion-button>
                            </div>
                        </div>
                    </section>
                    <section class="home__burukideak">
                        <div class="section--sp">
                            <div class="wrp">
                                <div class="section__header flex__wrp align--center">
                                    <div class="section__title title__l">{{ $t("categories.burukideak") }}</div>
                                    <div class="section__link btn__link">
                                        <router-link class="main__menu__link" v-bind:to="'/' + language + '/burukideak'">{{ $t("general.todos") }}</router-link>
                                    </div>
                                </div>
                                <flickity v-if="Object.keys(burukideak).length > 0" ref="flickityHome" class="flickity  swatch__slide" :options="flickityOptions" >
                                  <div v-for="(buro, key) in burukideak" :key="key" class="slide__cell">
                                    <Burukide-Excerpt :burukide="buro"/>
                                  </div>
                                </flickity>
                            </div>
                        </div>
                    </section>
                    <section class="home__nabarmenak">
                        <div class="section--sp">
                            <div class="wrp">
                                <div class="section__header flex__wrp align--center">
                                    <div class="section__title title__l">{{ $t("categories.nabarmenak") }}</div>
                                    <div class="section__link btn__link">
                                        <router-link v-bind:to="'/' + language + '/destacados/'">{{ $t("general.todos") }}</router-link>
                                    </div>
                                </div>
                                <div class="section__container">
                                    <div class="featured__answer" v-bind:key="answer" v-for="answer in destacados">
                                        <AnswerExcerpt :ans="answer" :trim="true" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="home__albisteak" v-if="noticias.length > 0">
                        <div class="section--sp">
                            <div class="wrp">
                                <div class="section__header flex__wrp align--center">
                                    <div class="section__title title__l">{{ $t("categories.albisteak") }}</div>
                                    <div class="section__link btn__link">
                                        <router-link v-bind:to="'/' + language + '/noticias/'">{{ $t("general.todos") }}</router-link>
                                    </div>
                                </div>
                                <div class="section__container">
                                    <div class="featured__new" v-bind:key="noticia" v-for="noticia in noticias">
                                        <NewExcerpt :noti="noticia" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="home__inkestak" v-if="encuestaId != ''">
                        <div class="section--sp">
                            <div class="wrp">
                                <div class="section__header">
                                    <div class="section__pretitle pretitle">{{ $t("onboardingUser.inkestak.pretitle") }}</div>
                                    <div class="section__title title__l">{{ $t("onboardingUser.inkestak.title") }}</div>
                                </div>
                                <div class="poll__wrp text--center">
                                    <div class="poll__pretitle" v-html="encuesta.subject"></div>
                                    <div class="poll__question body__l" v-html="encuesta.title"></div>
                                    <div class="res__container">
                                        <div class="poll__options">
                                            <div class="poll__opt opt--a" data-q="rep1q" @click.prevent="encuestaRes('rep1q')">
                                                <div class="opt__text">{{ encuesta.rep1valor }}</div>
                                                <div
                                                    class="poll__res opt--a"
                                                    v-html="((encuesta.rep1q * 100) / (parseInt(encuesta.rep1q) + parseInt(encuesta.rep2q))).toFixed(0) + '%'"
                                                ></div>
                                            </div>
                                            <div class="poll__opt opt--b" data-q="rep2q" @click.prevent="encuestaRes('rep2q')">
                                                <div class="opt__text">{{ encuesta.rep2valor }}</div>
                                                <div
                                                    class="poll__res opt--b"
                                                    v-html="((encuesta.rep2q * 100) / (parseInt(encuesta.rep1q) + parseInt(encuesta.rep2q))).toFixed(0) + '%'"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="home__ekitaldiak" v-if="agenda.length > 0">
                        <div class="section--sp">
                            <div class="wrp">
                                <div class="section__header flex__wrp align--center">
                                    <div class="section__title title__l">{{ $t("onboardingUser.ekitaldiak.title") }}</div>
                                    <div class="section__link btn__link">
                                        <router-link v-bind:to="'/' + language + '/agenda/'" router-direction="back">{{ $t("general.todos") }}</router-link>
                                    </div>
                                </div>
                                <div class="section__container">
                                    <div class="featured__event flex" v-bind:key="evento" v-for="evento in agenda">
                                        <Appointment-Excerpt :appointment="evento" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Sdfooter />
            </div>
        </ion-content>
        <Sdmenu />
        <Galdetu-Float />
    </ion-page>
</template>
<script>
import { defineComponent} from "vue";
import { useIonRouter, IonContent, IonPage, IonButton, alertController, IonRefresher, IonRefresherContent, isPlatform, IonSpinner} from "@ionic/vue";
//import { ref } from 'vue';
import HeaderMenu from "@/components/HeaderMenu.vue";
import NewExcerpt from "@/components/NewExcerpt.vue";
import AnswerExcerpt from "@/components/AnswerExcerpt.vue";
import GaldetuFloat from "@/components/GaldetuFloat.vue";
import BurukideExcerpt from "@/components/BurukideExcerpt.vue";
import AppointmentExcerpt from "@/components/AppointmentExcerpt.vue";
import Sdmenu from "@/components/menu.vue";
//import { FreeMode } from 'swiper';
//import { Swiper, SwiperSlide } from 'swiper/vue';
//import 'swiper/css';
//import 'swiper/css/free-mode';
//import Profile from "@/views/Profile.vue";
import Flickity from 'vue-flickity';
import Sdfooter from "@/components/footer.vue";

//import { Preferences } from '@capacitor/preferences';

export default defineComponent({
    name: "OnboardingUserItem",
    components: {
		IonContent,
		IonPage,
		IonButton,
		HeaderMenu,
		Sdfooter,
		GaldetuFloat,
		NewExcerpt,
		AnswerExcerpt,
		BurukideExcerpt,
		AppointmentExcerpt,
		Sdmenu,
		IonRefresher,
		IonRefresherContent,
		IonSpinner,
		Flickity
    },
    data(){
		return {
			from: null,
			noticias:[],
			agenda:[],
			encuesta:[],
			destacados:[],
			burukideak:[],
			encuestaId:null,
			mensajes:null,
			Nslides:2,
			flickityOptions: {
				initialIndex: 1,
				contain: true,
				prevNextButtons: false,
				pageDots: false,
				watchCSS: true,
				groupCells: true
			}

		};
    },
    setup() {
        const ionRouter = useIonRouter();

		return { ionRouter};
    },

    ionViewWillEnter() {
        this.$el.querySelector(".page__body").classList.add("opened");
    },
    ionViewDidEnter() {
        this.checkNewMess();
        this.$el.querySelector(".page__body").classList.remove("opened");
    },
    ionViewDidLeave() {
        if (this.$el.querySelector(".wrp-spin")) {
            this.$el.querySelector(".wrp-spin").classList.remove("wrp-spin");
        }
        if (this.$el.querySelector(".spin")) {
            this.$el.querySelector(".spin").remove();
        }
    },
    async created() {},
    mounted() {
      this.dragEvents();
    },
    computed: {
        language() {
            this.connect(true);
            return this.$i18n.locale || document.documentElement.lang;
        },
        isAndroid() {
            return isPlatform("android");
        },
        isIos() {
            return isPlatform("ios");
        },
    },
    watch: {},
    methods: {
        Next() {
            this.$router.push("/" + this.language + "/noticias");
        },
        galdetu() {
            this.$router.push("/" + this.language + "/galdetu", "forward");
            // this.ionRouter.navigate(`/${this.language}/galdetu`);
        },
        gotoBack() {
            if (this.from.path != "/" && this.from.path != "/login-callback") {
                this.$router.go(-1);
            } else {
                this.$router.push("/" + this.language);
            }
		},
		async saveData(key, data) {
            this.setStorage(key, data, true);
        },
        async loadData() {
            this.burukideak = await this.getStorage("burukideak" + this.$i18n.locale, true);
            this.noticias = await this.getStorage("noticiasOn" + this.$i18n.locale, true);
            this.agenda = await this.getStorage("agendaOn" + this.$i18n.locale, true);
            this.destacados = await this.getStorage("destacadosOn" + this.$i18n.locale, true);
            this.encuesta = await this.getStorage("encuesta" + this.$i18n.locale, true);
            this.encuestaId = this.encuesta ? this.encuesta.id_content : "";
            const checkRes = await this.getStorage("checkRes");
            if (checkRes != null || checkRes == this.encuestaId) {
                this.$el.querySelector(".res__container").classList.add("poll-completed");
                let rp1 = ((this.encuesta.rep1q * 100) / (parseInt(this.encuesta.rep1q) + parseInt(this.encuesta.rep2q))).toFixed(2);
                this.$el.querySelector(".poll__opt.opt--a").style.minWidth = rp1 + "%";
                this.$el.querySelector(".poll__opt.opt--a").style.maxWidth = rp1 + "%";
                if (rp1 > 50) {
                    this.$el.querySelector(".poll__opt.opt--a").classList.add("poll-color");
                } else {
                    this.$el.querySelector(".poll__opt.opt--b").classList.add("poll-color");
                }
                this.$el.querySelector(".poll__opt.opt--b").style.minWidth = 100 - rp1 + "%";
                this.$el.querySelector(".poll__opt.opt--b").style.maxWidth = 100 - rp1 + "%";
            }
        },
        scrolling() {
            if (document.querySelector(".home__hero").getBoundingClientRect().bottom < 0) {
                document.querySelector(".float__btn").classList.add("float--up");
            } else {
                document.querySelector(".float__btn").classList.remove("float--up");
            }
        },
        async presentAlert(mgs) {
            const alert = await alertController.create({
                cssClass: "my-custom-class",
                header: "ERROR",
                subHeader: "Lo sentimos ha ocurrido un error",
                message: mgs,
                buttons: ["OK"],
            });
            await alert.present();
            const { role } = await alert.onDidDismiss();
            console.log("onDidDismiss resolved with role", role);
        },
        async encuestaRes(arg) {
            const checkRes = await this.getStorage("checkRes");

            if (checkRes == null || checkRes != this.encuestaId) {
                //this.saveData('checkRes',this.encuestaId);
                this.setStorage("checkRes", this.encuestaId);
                const user = await this.getStorage("user", true);
                const user_id = user.user_id;
                if (!Number.isInteger(user_id)) {
                    this.logout();
                    return;
                }
                let endpoint = this.$api_host + "/user/" + user_id + "/poll/";
                this.$axiosPrivate
                    .post(endpoint, {
                        lang: this.language,
                        resp: arg,
                        poll: this.encuestaId,
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.encuesta = response.data[0];
                            this.$el.querySelector(".res__container").classList.add("poll-completed");
                            let rp1 = ((this.encuesta.rep1q * 100) / (parseInt(this.encuesta.rep1q) + parseInt(this.encuesta.rep2q))).toFixed(2);
                            this.$el.querySelector(".poll__opt.opt--a").style.width = rp1 + "%";
                            if (rp1 > 50) {
                                this.$el.querySelector(".poll__opt.opt--a").classList.add("poll-color");
                            } else {
                                this.$el.querySelector(".poll__opt.opt--b").classList.add("poll-color");
                            }
                            this.$el.querySelector(".poll__opt.opt--b").style.width = 100 - rp1 + "%";
                        }
                    })
                    .catch((error) => {
                        if (!error) {
                            this.openToastOptions(`Ha ocurrido un error con el servidor, por favor intentelo más tarde`, "danger");
                        } else {
                            // console.log(error);
                            if (error.response.status === 409) {
                                this.openToastOptions(`Ha ocurrido un error`, "danger");
                            } else {
                                this.openToastOptions(error.response.data.error, "danger");
                            }
                        }
                    });
            } else {
                // console.log('res');
                if (this.encuesta) {
                    this.$el.querySelector(".res__container").classList.add("poll-completed");
                    let rp1 = ((this.encuesta.rep1q * 100) / (parseInt(this.encuesta.rep1q) + parseInt(this.encuesta.rep2q))).toFixed(2);
                    this.$el.querySelector(".poll__opt.opt--a").style.width = rp1 + "%";
                    if (rp1 > 50) {
                        this.$el.querySelector(".poll__opt.opt--a").classList.add("poll-color");
                    } else {
                        this.$el.querySelector(".poll__opt.opt--b").classList.add("poll-color");
                    }
                    this.$el.querySelector(".poll__opt.opt--b").style.width = 100 - rp1 + "%";
                }
            }
            document.querySelectorAll(".poll__opt").forEach((e) => {
                e.style.pointerEvents = "none";
            });
        },
        async connect(arg) {
            let timestamp = "";
            if (arg) {
                this.saveData("timestamp", "");
            } else {
                timestamp = await this.getStorage("timestamp", true);
            }

            const checkRes = await this.getStorage("checkRes");

            let endpoint = this.$api_host + "/onboard/list/?lang=" + this.$i18n.locale;

            if (timestamp == null || parseInt(timestamp) + 144000 < Date.now() || timestamp == "") {
                this.$axiosPrivate
                    .get(endpoint)
                    .then((response) => {
                        if (response.status === 200) {
                            this.noticias = response.data.noticias;
                            this.agenda = response.data.agenda;
                            this.encuesta = response.data.encuesta.length > 0 ? response.data.encuesta[0].encuesta[0] : "";
                            this.destacados = response.data.destacados;
                            this.burukideak = response.data.burukideak;
                            this.encuestaId = response.data.encuesta.length > 0 ? response.data.encuesta[0].id_content : "";
                            this.saveData("noticiasOn" + this.$i18n.locale, response.data.noticias);
                            this.saveData("burukideak" + this.$i18n.locale, response.data.burukideak);
                            this.saveData("agendaOn" + this.$i18n.locale, response.data.agenda);
                            if (response.data.encuesta.length > 0) {
                                this.saveData("encuesta" + this.$i18n.locale, response.data.encuesta[0].encuesta[0]);
                            } else {
                                this.saveData("encuesta" + this.$i18n.locale, "");
                            }
                            this.saveData("destacadosOn" + this.$i18n.locale, response.data.destacados);
                            this.saveData("timestamp", Date.now());

                            //console.log(checkRes);
                            if (checkRes != null && checkRes == this.encuestaId) {
                                this.$el.querySelector(".res__container").classList.add("poll-completed");
                                let rp1 = ((this.encuesta.rep1q * 100) / (parseInt(this.encuesta.rep1q) + parseInt(this.encuesta.rep2q))).toFixed(2);
                                this.$el.querySelector(".poll__opt.opt--a").style.minWidth = rp1 + "%";
                                this.$el.querySelector(".poll__opt.opt--a").style.maxWidth = rp1 + "%";
                                if (rp1 > 50) {
                                    this.$el.querySelector(".poll__opt.opt--a").classList.add("poll-color");
                                } else {
                                    this.$el.querySelector(".poll__opt.opt--b").classList.add("poll-color");
                                }
                                this.$el.querySelector(".poll__opt.opt--b").style.minWidth = 100 - rp1 + "%";
                                this.$el.querySelector(".poll__opt.opt--b").style.maxWidth = 100 - rp1 + "%";
                            }

                            // console.log('serverdata')
                            //document.querySelector('.chat__messages__container').scrollTo(0,document.querySelector('.chat__messages__container').scrollHeight);
                        }
                    })
                    .catch((error) => {
                        if (error.response.status === 409) {
                            this.openToastOptions(`Ha ocurrido un error`, "danger");
                        } else {
                            this.openToastOptions(error.response.data.error, "danger");
                        }
                    });
            } else {
                this.loadData();
                // console.log('loaddata')
            }
        },
        async actOnboard(event) {
            await this.connect(true);
            event.target.complete();
        },
        async checkNewMess() {
            document.querySelector(".page__body").style.opacity = 1;
            const user = await this.getStorage("user", true);
            const user_id = user.user_id;
            let endpoint = `${this.$api_host}/user/${user_id}/news`;

            this.$axiosPrivate
                .get(endpoint)
                .then((response) => {
                    if (response.status === 200) {
                        this.mensajes = response.data;
                        var m = parseInt(this.mensajes);
                        if (m > 0) {
                            this.$el.querySelectorAll(".new__messages").forEach(function (e) {
                                e.style.opacity = 1;
                            });
                        }
                    }
                })
                .catch((error) => {
                    if (error.response.status === 409) {
                        this.openToastOptions(`Ha ocurrido un error`, "danger");
                    } else {
                        this.openToastOptions(error.response.data.error, "danger");
                    }
                });


        },
        dragEvents() {
          setTimeout(() => {

            if(!this.$refs.flickityHome) return false;
            this.$refs.flickityHome.on( 'dragStart', () => {
              this.$refs.flickityHome.$el.closest('.flickity').classList.add('flickity-dragging');
            });

            this.$refs.flickityHome.on( 'dragMove', () => {
              this.$refs.flickityHome.$el.closest('.flickity').classList.add('flickity-dragging');
            });

            this.$refs.flickityHome.on( 'dragEnd', () => {
              this.$refs.flickityHome.$el.closest('.flickity').classList.remove('flickity-dragging');
            });

          }, 600);
        },


		}//fin methods
});
</script>