<template>
    <router-link v-bind:to="'/'+this.language+'/noticia/'+noti.id_content">
		<div class="new__pretitle pretitle" v-html="noti.not.organizacion +' | '+noti.not.fecha"></div>
		<div class="new__title body__l" v-html="noti.not.titulo"></div>
		<div class="new__img">
			<img class="ofc--img tt--ab" v-bind:src="noti.not.imagen" />
		</div>
    </router-link>
    <Sharebar :txalosNumber="noti.likes" :id="noti.id_content" :url="noti.not.urlNoticia"/>
</template>
<script>
import { defineComponent } from "vue";
import Sharebar from "@/components/Sharebar.vue";


export default defineComponent({
    name: "NewExcerpt",
    components: {
       Sharebar
    },
    props:{
		noti:Object


    },
    setup() {

    },
    computed: {
        language() {
             return this.$i18n.locale || document.documentElement.lang;
        }
    },
    methods:{

    }
 })

</script>
