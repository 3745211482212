<template>

    <div :id="id" :class="localClass">
        <p v-if="preTitle" class="text__pretitle">{{ preTitle }}</p>
        <h1 v-if="mainTitle" class="text__maintitle">{{ mainTitle }}</h1>
        <h2 v-if="title" class="text__title">{{ title }}</h2>
        <p v-if="subTitle" class="text__subtitle">{{ subTitle }}</p>
        <p v-if="excerpt" class="text__excerpt">{{ excerpt }}</p>
        <div v-if="description" class="text__description" v-html="description"></div>
        <slot></slot>
    </div>


    <!-- <button v-if="submit" type="submit" :id="id" :class="localClass" :name="name" :disabled="disabled"><slot></slot></button>
    <button v-else-if="type === 'button'" :id="id" :class="localClass" :disabled="disabled"><slot></slot></button>
    <a v-else :id="id" :class="[localClass, {[compClass + '--disabled']: disabled}]" :href="href" :target="target" :rel="localRel"><slot></slot></a> -->
</template>

<script>
export default {
    name: "TextItem",
    props: {
        auxClass: String,
        jsClass: String,
        modifiers: String,
        id: String,
        preTitle: String,
        mainTitle: String,
        title: String,
        subTitle: String,
        excerpt: String,
        description: String,
        progressBar: Boolean,
        data: String,
    },
    data() {
        return {
            compClass: "text",
            localClass: null,
            localAuxClass: this.auxClass,
            localJsClass: this.jsClass,
            localModifiers: this.modifiers,
            localData: this.data,
        };
    },
    computed: {
    },
    setup() { // props, context
    },
    created() {
        this.localClass = this.compClass;

        if (this.localModifiers !== undefined) {
            this.localModifiers = this.compClass + "--" + this.localModifiers.split(",").join(" " + this.compClass + "--");
            this.localClass += " " + this.localModifiers;
        }

        if (this.localAuxClass !== undefined) {
            this.localClass += " " + this.localAuxClass;
        }

        if (this.localJsClass !== undefined) {
            this.localClass += " " + this.localJsClass;
        }
    },
    mounted() {
        //console.log('mounted');
    },
    methods: {
    },
};
</script>
