<template>
    <ion-page>
        <Header-Title :position="'center'" :title="$t('categories.nabarmenak')" :principal="true"/>
		<ion-content :fullscreen="true" :forceOverscroll="false">
			<ion-refresher slot="fixed" @ionRefresh="this.actDestacados($event)" closeDuration="360ms" pullFactor="0.8">
                <ion-refresher-content v-if="isIos" pulling-icon="Default"></ion-refresher-content>
                <ion-refresher-content v-else ></ion-refresher-content>
            </ion-refresher>
			<div class="page__body">
        <div class="spin"><ion-spinner></ion-spinner></div>
				<div class="wrp wrp-spin">
					<div class="area__container">
                        <div class="featured__answer" v-bind:key="answer" v-for="(answer) in this.destacados">
                            <AnswerExcerpt :ans="answer" :trim="false"/>
                        </div>
					</div>
				</div><!-- fin wrp -->
                <div class="no__event flex__wrp align--center" style="opacity:0" v-if="this.destacados.length == 0">
                    <div class="text--center">
                        <h2 class="no__event__title title__l">{{ $t("nabarmenak.noevents.title") }}</h2>
                        <p class="no__event__text body__m">{{ $t("nabarbemak.noevents.text") }}</p>
                    </div>
                </div>
                <!-- fin no-events -->
			</div><!-- fin page__body -->
		<ion-infinite-scroll @ionInfinite="loadNext($event)" threshold="100px" id="infinite-scroll" :disabled="isDisabled" >
			<ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading more data...">
			</ion-infinite-scroll-content>
		</ion-infinite-scroll>
		</ion-content>
		<Galdetu-Float/>
    </ion-page>
</template>

<script>
import { defineComponent, ref } from "vue";
import { IonContent, IonPage, IonRefresher, IonRefresherContent, IonInfiniteScroll, IonInfiniteScrollContent, alertController, toastController, isPlatform, IonSpinner } from "@ionic/vue";

import HeaderTitle from "@/components/HeaderTitle.vue";
import GaldetuFloat from "@/components/GaldetuFloat.vue";
import AnswerExcerpt from "@/components/AnswerExcerpt.vue";
//import Sdmenu from "@/components/menu.vue";


import { chevronDownCircleOutline } from 'ionicons/icons';

// import "@/assets/js/ValidationRegister/init";
// import InputInfo from "@/assets/js/InputInfo/InputInfo";
// const { InputInfo } = require("@/assets/js/InputInfo/InputInfo");
// import { Preferences } from '@capacitor/preferences';


export default defineComponent({
    name: "destacadosItem",
    components: {
	IonContent,
	IonPage,
	HeaderTitle,
	//Sdmenu,
	GaldetuFloat,
	AnswerExcerpt,
	IonRefresher,
	IonRefresherContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
  IonSpinner

    },
    props:{

    },
    data(){
	return{
	from: null,
	destacados:[],
    last:null,

	}
    },

    setup() {

    const isDisabled = ref(false);
    const toggleInfiniteScroll = () => {
      isDisabled.value = !isDisabled.value;
    }

	return { chevronDownCircleOutline, isDisabled, toggleInfiniteScroll}

    },
    beforeRouteEnter(to, from, next) {
	next((vm) => {
	vm.from = from;
	});
    },
    async created() {
        //this.connect()

	},
  ionViewWillEnter() {
    this.$el.querySelector('.page__body').classList.add('opened')
  },
  ionViewDidEnter() {
    this.$el.querySelector('.page__body').classList.remove('opened');
  },
  ionViewDidLeave(){
    if(this.$el.querySelector('.wrp-spin')){
    this.$el.querySelector('.wrp-spin').classList.remove('wrp-spin');
  }
    if(this.$el.querySelector('.spin')){
      this.$el.querySelector('.spin').remove();
    }
  },
    mounted() {
        var that = this;
        var con = that.connect();
        return {con}

		},
    computed: {
        language() {
            return this.$i18n.locale || document.documentElement.lang;
            },
            isAndroid() {
            return isPlatform("android");
            },
            isIos() {
            return isPlatform("ios");
        },
    },
    methods:{
        async actDestacados(event) {
            await this.connect(true);
            event.target.complete();
        },
        async connect(arg){
            var that = this;
            if(arg){
                that.saveData('timestampDestacados'+ this.$i18n.locale,'')
            } //si arg=true lo actualizamos
            console.log('connect');
            //const access_token = await this.getStorage("access_token", true);
            //const user = await this.getStorage("user", true);
            //const user_id=user.user_id;
            const timestamp = await this.getStorage("timestampDestacados"+ this.$i18n.locale, true);
            let endpoint = this.$api_host + "/destacados/list/?lang=" + this.$i18n.locale + "&limit=20";

            if(timestamp ==null || parseInt(timestamp)+(1440000) < Date.now() || timestamp == ''){
                console.log('serverdata');
                that.$axiosPrivate.get(endpoint
                ).then((response) => {
                    if (response.status === 200) {
                        that.destacados = (response.data.length > 0)?response.data:'';
                        that.saveData('destacados'+ this.$i18n.locale, response.data);
                        that.saveData('timestampDestacados'+ this.$i18n.locale, Date.now());
                        if(response.data.length > 0) {
                            that.last=that.destacados[that.destacados.length -1].id_content;
                        }else{
                            that.$el.querySelector('.no__event').style.opacity=1;
                        }


                    }
                }).catch((error) => {

                    if (error.response.status === 409) {
                        this.openToastOptions(`Ha ocurrido un error`, "danger");
                    } else {
                        this.openToastOptions(error.response.data.error, "danger");
                    }

                });

            }else{
                that.loadData();
                console.log('loaddata')
            }

        },
        async saveData(key, data) {
            this.setStorage(key, data, true);
        },
        async loadData() {
            this.destacados = await this.getStorage("destacados"+ this.$i18n.locale, true);
            this.$el.querySelector('.no__event').style.opacity=1;
        },
        loadNext(ev){
            ev.target.disabled = true;
            var that=this;
            let endpoint = this.$api_host + "/destacados/list/?lang=" + this.$i18n.locale + "&limit=20&last="+that.last;
            that.$axiosPrivate.get(endpoint
            ).then((response) => {
                if (response.status === 200) {
                    if(response.data.length == 0){
                        console.log('No hay más respuestas destacadas');
                    }
                    response.data.forEach(function(i){
                        that.destacados.push(i);
                    })

                    that.saveData('destacados'+ this.$i18n.locale, that.destacados);
                    that.saveData('timestampDestacados'+ this.$i18n.locale, Date.now());

                    that.last=that.destacados[that.destacados.length -1].id_content;

                }
            }).catch((error) => {

                if (error.response.status === 409) {
                    this.openToastOptions(`Ha ocurrido un error`, "danger");
                } else {
                    this.openToastOptions(error.response.data.error, "danger");
                }

            });

        },
        async presentAlert(mgs) {
            const alert = await alertController
            .create({
                cssClass: 'my-custom-class',
                header: 'ERROR',
                subHeader: 'Lo sentimos ha ocurrido un error',
                message: mgs,
                buttons: ['OK'],
            });
            await alert.present();
            const { role } = await alert.onDidDismiss();
            console.log('onDidDismiss resolved with role', role);
        },
        async openToast(mgs) {
            const toast = await toastController
            .create({
                message: mgs,
                duration: 2000
            })
            return toast.present();
        },

	}
});
</script>
